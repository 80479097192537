import React, { useEffect, lazy } from "react";

import { Route, Switch } from "react-router-dom";
//scss
import "react-datepicker/dist/react-datepicker.css";
import "./assets/dashboard.scss";
import "./assets/dashboard-responsive.scss";
import "./assets/Index.scss";
import "./assets/export.scss";

//component
import ProtectedRoute from "./components/protectedRoute/index";
import { initialize } from "utils/analytics";

const Home = lazy(() => import("./pages/home"));
const Schedules = lazy(() => import("pages/schedules"));
const AboutUs = lazy(() => import("pages/aboutUs"));
const OurServicesDepot = lazy(() => import("pages/ourServices"));
const OurServicesTruck = lazy(() => import("pages/ourServices"));
const OurServicesPort = lazy(() => import("pages/ourServices"));
const OurServicesCustoms = lazy(() => import("pages/ourServices"));
const HelpCenter = lazy(() => import("pages/helpCenter"));
const Partner = lazy(() => import("pages/partner"));
const Career = lazy(() => import("pages/career"));
const NotFound = lazy(() => import("pages/notFound"));
const TermsAndConditions = lazy(() => import("pages/termsAndConditions"));
const HandleSchedules = lazy(() => import("pages/handleSchedules"));
const RequestSchedule = lazy(() => import("pages/requestSchedule"));
const EDocument = lazy(() => import("pages/e-document"));

const News = lazy(() => import("pages/news"));
const NewsDetail = lazy(() => import("pages/news-detail"));
const EventsDetail = lazy(() => import("pages/events-detail"));

const BookingTruck = lazy(() => import("pages/truck"));
const BookingFreight = lazy(() => import("pages/freight"));
const SignUp = lazy(() => import("pages/auth/signup"));
const SignIn = lazy(() => import("pages/auth/signin"));

// const FreightSchedule = lazy(() => import('pages/freight/Schedule/Schedule'));

//dashboard
const DashboardHome = lazy(() => import("pages/dashboard/Home"));
const DashboardTruck = lazy(() => import("pages/dashboard/Truck/truck"));
const DashboardFreight = lazy(() =>
  import("pages/dashboard/FreightList/pages/Container")
);
const DashboardDocument = lazy(() =>
  import("pages/dashboard/Document/document")
);
const DashboardMonitor = lazy(() => import("pages/dashboard/Monitor/monitor"));
const DashboardHistory = lazy(() => import("pages/dashboard/History/history"));
const DashboardSetting = lazy(() => import("pages/dashboard/Setting/setting"));
const DashboardExport = lazy(() => import("pages/dashboard/Export/export"));
const DashboardGatePass = lazy(() =>
  import("pages/dashboard/GatePass/pages/main/gatePass")
);
const DashboardTagihanSaya = lazy(() =>
  import("pages/dashboard/TagihanSaya/tagihanSaya")
);
const BayarTagihan = lazy(() =>
  import("pages/dashboard/TagihanSaya/MetodePembayaran/bayar")
);

//Dashboard Freight Order
const DashboardFreightMain = lazy(() =>
  import("pages/dashboard/FreightOrder/pages/Main")
);
const DashboardFreightResult = lazy(() =>
  import("pages/dashboard/FreightOrder/pages/SearchResult")
);
const DashboardFreightCompleteData = lazy(() =>
  import("pages/dashboard/FreightOrder/pages/CompleteData")
);
const DashboardFreightAddons = lazy(() =>
  import("pages/dashboard/FreightOrder/pages/Addons")
);
const DashboardFreightSummary = lazy(() =>
  import("pages/dashboard/FreightOrder/pages/Summary")
);
const DashboardFreightBookingSuccess = lazy(() =>
  import("pages/dashboard/FreightOrder/pages/BookingSuccess")
);

// Dahsboard Vendor - Trucker
const DashboardVendor = lazy(() =>
  import("pages/dashboard/Vendor/Trucker/Dashboard")
);
const VendorListOrder = lazy(() =>
  import("pages/dashboard/Vendor/Trucker/ListOrder")
);
const VendorCreditNote = lazy(() =>
  import("pages/dashboard/Vendor/Trucker/CreditNote")
);
const SettingVehicle = lazy(() =>
  import("pages/dashboard/Vendor/Trucker/Settings/Vehicle")
);
const SettingDriver = lazy(() =>
  import("pages/dashboard/Vendor/Trucker/Settings/Driver")
);

//Dashboard Freight List

// Dashboard Gatepass List
const DashboardGatePassList = lazy(() =>
  import("./pages/dashboard/GatePass/pages/listGatepass/index")
);

function App(props) {
  useEffect(() => {
    initialize();
  }, []);

  return (
    <Switch>
      {/* General */}
      <Route exact path={"/"} component={Home} />
      <Route path={"/schedules"} component={Schedules} />
      <Route path={"/bookingTruck"} component={BookingTruck} />
      <Route path={"/bookingFreight"} component={BookingFreight} />
      <Route path={"/signIn"} component={SignIn} />
      <Route path={"/signUp"} component={SignUp} />
      <Route path={"/aboutUs"} component={AboutUs} />
      <Route path={"/ourServices/depot"} component={OurServicesDepot} />
      <Route path={"/ourServices/truck"} component={OurServicesTruck} />
      <Route path={"/ourServices/port"} component={OurServicesPort} />
      <Route path={"/ourServices/customs"} component={OurServicesCustoms} />
      <Route path={"/helpCenter"} component={HelpCenter} />
      <Route path={"/partner"} component={Partner} />
      <Route path={"/career"} component={Career} />
      <Route path={"/termsAndConditions"} component={TermsAndConditions} />
      <Route path={"/handleSchedules"} component={HandleSchedules} />
      <Route path={"/requestSchedule"} component={RequestSchedule} />
      <Route path={"/e-document"} component={EDocument} />
      <Route path={"/news"} component={News} />
      <Route path={"/news-detail/:id"} component={NewsDetail} />
      <Route path={"/events-detail/:id"} component={EventsDetail} />
      {/* <Route path={'/freightSchedule'} component={FreightSchedule} /> */}

      {/* Dashboard */}
      <ProtectedRoute exact path={"/dashboard"} component={DashboardHome} />
      {/* Vendor */}
      <ProtectedRoute exact path={"/vendor"} component={DashboardVendor} />
      <ProtectedRoute
        exact
        path={"/vendor/listOrder"}
        component={VendorListOrder}
      />
      <ProtectedRoute
        exact
        path={"/vendor/creditNote"}
        component={VendorCreditNote}
      />
      <ProtectedRoute
        exact
        path={"/vendor/settings/vehicle"}
        component={SettingVehicle}
      />
      <ProtectedRoute
        exact
        path={"/vendor/settings/driver"}
        component={SettingDriver}
      />

      <ProtectedRoute
        path={"/dashboard/list/document"}
        component={DashboardDocument}
      />
      <ProtectedRoute
        path={"/dashboard/monitor"}
        component={DashboardMonitor}
      />
      <ProtectedRoute
        path={"/dashboard/history"}
        component={DashboardHistory}
      />
      <ProtectedRoute
        path={"/dashboard/setting"}
        component={DashboardSetting}
      />
      <ProtectedRoute
        path={"/dashboard/list/truck"}
        component={DashboardTruck}
      />
      <ProtectedRoute
        path={"/dashboard/add/truck"}
        component={DashboardExport}
      />
      <ProtectedRoute
        path={"/dashboard/e-document/gatePass"}
        component={DashboardGatePass}
      />
      <ProtectedRoute
        path={"/dashboard/tagihan"}
        component={DashboardTagihanSaya}
      />
      <ProtectedRoute
        path={"/dashboard/bayarTagihan"}
        component={BayarTagihan}
      />
      {/*Dashboard Freight */}
      <ProtectedRoute
        exact
        path={"/dashboard/add/freight"}
        component={DashboardFreightMain}
      />
      <Route path={"/dashboard/list/freight"} component={DashboardFreight} />
      <Route
        path={"/dashboard/add/freight/result"}
        component={DashboardFreightResult}
      />
      <Route
        path={"/dashboard/add/freight/completedata"}
        component={DashboardFreightCompleteData}
      />
      <Route
        path={"/dashboard/add/freight/addons"}
        component={DashboardFreightAddons}
      />
      <Route
        path={"/dashboard/add/freight/summary"}
        component={DashboardFreightSummary}
      />
      <Route
        path={"/dashboard/add/freight/bookingsuccess"}
        component={DashboardFreightBookingSuccess}
      />

      <Route
        path={"/dashboard/list/e-document/gatePass"}
        component={DashboardGatePassList}
      />

      <Route
        path={"/dashboard/add/freight/result"}
        component={DashboardFreightResult}
      />
      <Route
        path={"/dashboard/add/freight/completedata"}
        component={DashboardFreightCompleteData}
      />
      <Route
        path={"/dashboard/add/freight/addons"}
        component={DashboardFreightAddons}
      />
      <Route
        path={"/dashboard/add/freight/summary"}
        component={DashboardFreightSummary}
      />
      <Route
        path={"/dashboard/add/freight/bookingsuccess"}
        component={DashboardFreightBookingSuccess}
      />
      <Route component={NotFound} />
    </Switch>
  );
}

export default App;
