import { updateOrAddCartItems, getCartData } from '../../services/Export/addOrUpdateCartItem'

export const ADD_OR_UPDATE_CART_ITEM_REQUEST = "ADD_OR_UPDATE_CART_ITEM_REQUEST"
export const ADD_OR_UPDATE_CART_ITEM_SUCCESS = "ADD_OR_UPDATE_CART_ITEM_SUCCESS"
export const ADD_OR_UPDATE_CART_ITEM_FAILURE = "ADD_OR_UPDATE_CART_ITEM_FAILURE"

const addOrUpdateCartItemRequest = () => {
    return {
      type: ADD_OR_UPDATE_CART_ITEM_REQUEST
    }
  }
  
  const addOrUpdateCartItemSuccess = cartItemData => {
    return {
      type: ADD_OR_UPDATE_CART_ITEM_SUCCESS,
      cartItemData
    }
  }
  
  const addOrUpdateCartItemFailure = () => {
    return {
      type: ADD_OR_UPDATE_CART_ITEM_FAILURE
    }
  }

export const addOrUpdateCartItem = (carData) => async dispatch => {
  console.log('heree')
    dispatch(addOrUpdateCartItemRequest())
    const response = await updateOrAddCartItems(carData)
    if (response) {
        dispatch(addOrUpdateCartItemSuccess(response))
        return true
    } else {
      dispatch(addOrUpdateCartItemFailure())
      return false
    }
  }

  export const getCart = (cartID) => async dispatch => {
    dispatch(addOrUpdateCartItemRequest())
    const response = await getCartData(cartID)
    if (response) {
        dispatch(addOrUpdateCartItemSuccess(response))
        return true
    } else {
      dispatch(addOrUpdateCartItemFailure())
      return false
    }
  }