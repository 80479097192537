import {
    GET_ALL_SERVICES_REQUEST,
    GET_ALL_SERVICES_SUCCESS,
    GET_ALL_SERVICES_FAILURE
  } from "../actions";
  
  export default (
    state = {
        getError: false,
        additionalService : [],
    },
    action
  ) => {
    switch (action.type) {
      case GET_ALL_SERVICES_REQUEST:
        return {
          ...state,
          getError:false
        };
      case GET_ALL_SERVICES_SUCCESS:
        return {
          ...state,
          getError:false,
          additionalService: action.additionalService
        };
      case GET_ALL_SERVICES_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };