import { signInUser, signUpVendor, signUpCustomer } from 'services/authService';
import { checkCookie, setCookie, deleteCookie, getCookie } from 'utils/cookies';
import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	VERIFY_REQUEST,
	VERIFY_SUCCESS,
	REGISTER_SUCCESS,
	REGISTER_FAILURE,
	REGISTER_REQUEST,
} from './types';

// export const registerUser = (type, values) => dispatch => {
//   dispatch(requestRegister())

//   const data = {
//     name: values.name,
//     username: values.name,
//     password: values.password
//   }

//   registerUserIn(type, data).then(response => {
//     if (response.data) {
//       setCookie('token', response.data.token, 1)
//       dispatch(receiveRegister(response.data))
//     } else {
//       // message.error('Register Error!')
//     }
//   }).catch(error => {
//     // message.error('Register Error!')
//     dispatch(registerError())
//   })
// }

export const loginUser = value => async dispatch => {
	const { password, userName } = value;
	try {
		dispatch({ type: LOGIN_REQUEST });
		const response = await signInUser({ userName, password: btoa(password) });
		console.log(response.data.value.userProfile);
		if (response.data) {
			dispatch({
				type: LOGIN_SUCCESS,
				user: response.data.value,
				userId: response.data.value.userProfile.userID,
			});
			setCookie('token', response.data.value.tokenInfo.jwt, 1);
			setCookie('userId', response.data.value.userProfile.userID, 1);
			setCookie('vendorId', response.data.value.userProfile.vendorID, 1);
		} else {
			dispatch({ type: LOGIN_FAILURE });
		}
		return true;
	} catch (error) {
		// dispatch({ type: INSERT_USER_INFO_FAILED, error: error.message });
		dispatch({ type: LOGIN_FAILURE });
		return error.response.data.value[0];
	}
};

export const signUp = (type, value) => async dispatch => {
	// console.log(type, value)
	try {
		dispatch({ type: REGISTER_REQUEST });
		let response = {};
		if (type === 'Vendor') {
			response = await signUpVendor(value);
		} else {
			response = await signUpCustomer(value);
		}
		console.log(response, 'response');
		// dispatch({ type: REGISTER_SUCCESS, userRegister: response })
		dispatch({ type: REGISTER_SUCCESS, userRegister: response.data.value });
	} catch (error) {
		console.log(error.response.data.errors[0], 'error');
		dispatch({ type: REGISTER_FAILURE, errorSignup: error.response.data.errors[0] });
	}
};

// export const logoutUser = () => dispatch => {
//   dispatch(requestLogout())

//   deleteCookie()

//   const cookie = checkCookie()

//   if (cookie === null) {
//     dispatch(receiveLogout())
//   } else {
//     dispatch(logoutError())
//   }
// }

export const verifyAuth = () => dispatch => {
	// deleteCookie();
	dispatch({ type: VERIFY_REQUEST });
	// console.log(getCookie('token'));
	const cookie = checkCookie();
	const userId = getCookie('userId');
	if (cookie !== null) {
		dispatch({
			type: LOGIN_SUCCESS,
			user: cookie,
			userId: userId,
		});
	}
	dispatch({ type: VERIFY_SUCCESS });
};
