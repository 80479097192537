import axios from 'axios';
import { getCookie } from 'utils/cookies';

const token = getCookie('token');

export default axios.create({
	// timeout: 10000,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		Authorization: token,
	},
});
