import axios from 'services/vendorAxios/vendorAxios';
import { vendorScheduler } from './init';
import { getCookie } from 'utils/cookies';
const vendorId = getCookie('vendorId');
// console.log(vendorId);
const vendorId2 = 'VEND-20200429-0002';

export const getListOrder = (orderType, dateFrom, dateTo) =>
	axios.get(
		vendorScheduler +
			`/listOrder?orderType=${orderType}&dateFrom=${dateFrom}&dateTo=${dateTo}&vendorId=${vendorId}&search=`
	);

export const assignDriver = payload =>
	axios.post(vendorScheduler + '/assignDriver', payload);

export const getListCn = (orderType, status, dateFrom, dateTo) =>
	axios.get(
		vendorScheduler +
			`/listCreditNote?orderType=${orderType}&dateFrom=${dateFrom}&dateTo=${dateTo}&statusPembayaran=${status}&vendorId=${vendorId}`
	);

export const getListOrderDetail = (orderId, orderType) =>
	axios.get(
		vendorScheduler +
			`/listVendorOrderDetail?orderType=${orderType}&vendorOrderId=${orderId}`
	);

export const getTotalCn = (dateFrom, dateTo, status) =>
	axios.get(
		vendorScheduler +
			`/totalCreditNoteByStatus?vendorId=${vendorId}&dateFrom=2020-09-21&dateTo=2021-09-21&status=${status}`
	);

export const getListContainer = orderMgtDetailId =>
	axios.get(
		vendorScheduler + `/listContainer?orderManagementDetailID=${orderMgtDetailId}`
	);

export const getListDriver = () =>
	axios.get(vendorScheduler + `/listDriver?vendorId=${vendorId}`);

export const getListVehicle = () =>
	axios.get(vendorScheduler + `/listVehicle?vendorId=${vendorId}`);

export const insertDriver = payload =>
	axios.post(vendorScheduler + '/insertDriver', payload);

export const updateDriver = payload =>
	axios.post(vendorScheduler + '/updateDriver', payload);

export const deleteDriver = payload =>
	axios.post(vendorScheduler + '/deleteDriver', payload);

export const insertVehicle = payload =>
	axios.post(vendorScheduler + '/insertVehicle', payload);

export const updateVehicle = payload =>
	axios.post(vendorScheduler + '/updateVehicle', payload);

export const deleteVehicle = payload =>
	axios.post(vendorScheduler + '/deleteVehicle', payload);
