import {
    DATAPROVINSI,
    DATAKOTA,
    DATAKECAMATAN,
    DATAKELURAHAN
} from "actions/types"

export default (
    state = {
        dataProvinsi: [],
        dataKota: [],
        dataKecamatan: [],
        dataKelurahan: []
    },
    action
) => {
    switch (action.type) {
        case DATAPROVINSI:
            return {
                ...state,
                dataProvinsi: action.dataProvinsi
            }
        case DATAKOTA:
            return {
                ...state,
                dataKota: action.dataKota
            }
        case DATAKECAMATAN:
            return {
                ...state,
                dataKecamatan: action.dataKecamatan
            }
        case DATAKELURAHAN:
            return {
                ...state,
                dataKelurahan: action.dataKelurahan
            }
        default:
            return state
    }
}