import update from "immutability-helper";

export default function reducer(
  state = {
    origins: [],
    destinations: [],
    origins_offline: [],
    destinations_offline: [],
  },
  action
) {
  const _updateList = (data, list) => {
    let updated = update(list, { $set: data });
    list = updated;
    return list;
  };
  switch (action.type) {
    case "PORT_ORIGINS": {
      return {
        ...state,
        origins: _updateList(action.payload, state.origins),
        origins_offline: _updateList(action.payload, state.origins),
      };
    }

    case "PORT_DESTINATIONS": {
      return {
        ...state,
        destinations: _updateList(action.payload.ports, state.destinations),
        destinations_offline: _updateList(
          action.payload.ports,
          state.destinations
        ),
      };
    }

    case "PORT_GET_ALL": {
      return {
        ...state,
        origins: action.payload.ports,
        destinations: action.payload.ports,
        origins_offline: action.payload.ports,
        destinations_offline: action.payload.ports,
      }
    }

    case "PORT_ORIGINS_SEARCH": {
      return {
        ...state,
        origins_offline: _updateList(action.payload, state.origins_offline),
      };
    }

    case "PORT_DESTINATIONS_SEARCH": {
      return {
        ...state,
        destinations_offline: _updateList(
          action.payload,
          state.destinations_offline
        ),
      };
    }
    default:
      return state;
  }
}
