import { getAdditionalService } from '../services/getAllServices'

export const GET_ALL_SERVICES_REQUEST = "GET_ALL_SERVICES_REQUEST"
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS"
export const GET_ALL_SERVICES_FAILURE = "GET_ALL_SERVICES_FAILURE"

const getAllServicesRequest = () => {
    return {
      type: GET_ALL_SERVICES_REQUEST
    }
  }
  
  const getAllServicesSuccess = additionalService => {
    return {
      type: GET_ALL_SERVICES_SUCCESS,
      additionalService
    }
  }
  
  const getAllServicesFailure = () => {
    return {
      type: GET_ALL_SERVICES_FAILURE
    }
  }

export const getAdditionalServices = () => async dispatch => {
    dispatch(getAllServicesRequest())
    const response = await getAdditionalService()
    if (response) {
        dispatch(getAllServicesSuccess(response))
        return true
    } else {
      dispatch(getAllServicesFailure())
      return false
    }
  }