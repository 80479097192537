import axios from "axios";
import { callAPI, base, vendorScheduler } from "./init";

export default axios.create({
  // timeout: 10000,
  // baseURL: base,
  // baseURL: "http://localhost:8080/api/v1",

  baseURL: "https://demo.logol-dev.com/logol-rest-api-dev/cms/Api",
  // headers: {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // },
});
