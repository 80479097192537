import { getContainerTypeListData } from '../../services/Export/getContainerTypeListing'

export const GET_CONTAINER_TYPE_LIST_REQUEST = "GET_CONTAINER_TYPE_LIST_REQUEST"
export const GET_CONTAINER_TYPE_LIST_SUCCESS = "GET_CONTAINER_TYPE_LIST_SUCCESS"
export const GET_CONTAINER_TYPE_LIST_FAILURE = "GET_CONTAINER_TYPE_LIST_FAILURE"

const getContainerTypeListDataequest = () => {
    return {
      type: GET_CONTAINER_TYPE_LIST_REQUEST
    }
  }
  
  const getContainerTypeListDataSuccess = containerTypeListData => {
    return {
      type: GET_CONTAINER_TYPE_LIST_SUCCESS,
      containerTypeListData
    }
  }
  
  const getContainerTypeListDataFailure = () => {
    return {
      type: GET_CONTAINER_TYPE_LIST_FAILURE
    }
  }

export const getContainerTypeList = () => async dispatch => {
    dispatch(getContainerTypeListDataequest())
    const response = await getContainerTypeListData()
    if (response) {
        dispatch(getContainerTypeListDataSuccess(response))
        return true
    } else {
      dispatch(getContainerTypeListDataFailure())
      return false
    }
  }