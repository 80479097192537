import { getDepoData } from '../../services/Export/searchDepo'

export const SEARCH_DEPO_REQUEST = "SEARCH_DEPO_REQUEST"
export const SEARCH_DEPO_SUCCESS  = "SEARCH_DEPO_SUCCESS"
export const SEARCH_DEPO_FAILURE  = "SEARCH_DEPO_FAILURE"

const getDepoRequest = () => {
    return {
      type: SEARCH_DEPO_REQUEST
    }
  }
  
  const getDepoSuccess = searchDepoData => {
    return {
      type: SEARCH_DEPO_SUCCESS,
      searchDepoData
    }
  }
  
  const getDepoFailure = () => {
    return {
      type: SEARCH_DEPO_FAILURE
    }
  }

export const getDepo = (term) => async dispatch => {
    dispatch(getDepoRequest())
    const response = await getDepoData(term)
    if (response) {
        dispatch(getDepoSuccess(response))
        return true
    } else {
      dispatch(getDepoFailure())
      return false
    }
  }