import {
  SCROLLPOSITION,
  TYPEBOOKING,
  TYPEEXPORIMPORT,
  BOOKINGTRUCK,
  GETCONTAINER,
  ALLPORT,
  RATECONTAINER,
  RESNEWORDER,
} from "actions/types";

export default (
  state = {
    scrollposition: 0,
    typeBooking: "truck",
    typeExportImport: "export",
    listContainer: [],
    listPort: [],
    bookingTruck: {
      addressA: {},
      addressB: {},
      date: "",
      container: {
        price: 0,
        quantity: 0,
      },
    },
    rateContainer: {},
    resNewOrder: "",
    idBooking: "",
  },
  action
) => {
  switch (action.type) {
    case SCROLLPOSITION:
      return {
        ...state,
        scrollposition: action.scrollposition,
      };
    case TYPEBOOKING:
      return {
        ...state,
        typeBooking: action.typeBooking,
      };
    case TYPEEXPORIMPORT:
      return {
        ...state,
        typeExportImport: action.typeExportImport,
      };
    case BOOKINGTRUCK:
      return {
        ...state,
        bookingTruck: action.bookingTruck,
      };
    case GETCONTAINER:
      return {
        ...state,
        listContainer: action.listContainer,
      };
    case ALLPORT:
      return {
        ...state,
        listPort: action.listPort,
      };
    case RATECONTAINER:
      return {
        ...state,
        rateContainer: action.rateContainer,
      };
    case RESNEWORDER:
      return {
        ...state,
        resNewOrder: action.resNewOrder,
        idBooking: action.idBooking,
      };
    default:
      return state;
  }
};
