import { getFactoryListData } from '../../services/Export/factoryList'

export const GET_FACTORY_LIST_REQUEST = "GET_FACTORY_LIST_REQUEST"
export const GET_FACTORY_LIST_SUCCESS = "GET_FACTORY_LIST_SUCCESS"
export const GET_FACTORY_LIST_FAILURE = "GET_FACTORY_LIST_FAILURE"

const getFactoryListDataequest = () => {
    return {
      type: GET_FACTORY_LIST_REQUEST
    }
  }

  
  const getFactoryListDataSuccess = factoryListData => {
    return {
      type: GET_FACTORY_LIST_SUCCESS,
      factoryListData
    }
  }
  
  const getFactoryListDataFailure = () => {
    return {
      type: GET_FACTORY_LIST_FAILURE
    }
  }

export const getFactoryList = (terms) => async dispatch => {
    dispatch(getFactoryListDataequest())
    const params = `?terms=${terms}`
    const response = await getFactoryListData(params)
    if (response) {
        dispatch(getFactoryListDataSuccess(response.data.value.data))
        return true
    } else {
      dispatch(getFactoryListDataFailure())
      return false
    }
  }