import {
    GET_DESTINATION_PICKER_REQUEST,
    GET_DESTINATION_PICKER_SUCCESS,
    GET_DESTINATION_PICKER_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        destinationPickerIds : {},
    },
    action
  ) => {
    switch (action.type) {
      case GET_DESTINATION_PICKER_REQUEST:
        return {
          ...state,
          getError:false
        };
      case GET_DESTINATION_PICKER_SUCCESS:
        return {
          ...state,
          getError:false,
          destinationPickerIds: action.destinationPickerIds
        };
      case GET_DESTINATION_PICKER_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };