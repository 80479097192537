import { getRequestPass } from '../../services/TruckBookingList/requestGatePass'

export const REQUEST_GATEPASS_REQUEST = "REQUEST_GATEPASS_REQUEST"
export const REQUEST_GATEPASS_SUCCESS  = "REQUEST_GATEPASS_SUCCESS"
export const REQUEST_GATEPASS_FAILURE  = "REQUEST_GATEPASS_FAILURE"

const getGatepassRequest = () => {
    return {
      type: REQUEST_GATEPASS_REQUEST
    }
  }
  
  const requestGatePassSuccess = gatePassData => {
    return {
      type: REQUEST_GATEPASS_SUCCESS,
      gatePassData
    }
  }
  
  const grequestGatePassFailure = () => {
    return {
      type: REQUEST_GATEPASS_FAILURE
    }
  }

export const getGatePass = () => async dispatch => {
    dispatch(getGatepassRequest())
    const response = await getRequestPass()
    if (response) {
        dispatch(requestGatePassSuccess(response))
        return true
    } else {
      dispatch(grequestGatePassFailure())
      return false
    }
  }