import {
	GET_VENDOR_LIST_ORDER,
	GET_VENDOR_LIST_ORDER_SUCCESS,
	GET_VENDOR_LIST_ORDER_FAILED,
	GET_VENDOR_LIST_CN,
	GET_VENDOR_LIST_CN_SUCCESS,
	GET_VENDOR_LIST_CN_FAILED,
	GET_VENDOR_LIST_ORDER_DETAIL,
	GET_VENDOR_LIST_ORDER_DETAIL_SUCCESS,
	GET_VENDOR_LIST_ORDER_DETAIL_FAILED,
	GET_VENDOR_UNPAID_CN,
	GET_VENDOR_UNPAID_CN_SUCCESS,
	GET_VENDOR_UNPAID_CN_FAILED,
	GET_VENDOR_PAID_CN,
	GET_VENDOR_PAID_CN_SUCCESS,
	GET_VENDOR_PAID_CN_FAILED,
	GET_VENDOR_LIST_CONTAINER,
	GET_VENDOR_LIST_CONTAINER_SUCCESS,
	GET_VENDOR_LIST_CONTAINER_FAILED,
	VENDOR_ASSIGN_DRIVER,
	VENDOR_ASSIGN_DRIVER_SUCCESS,
	VENDOR_ASSIGN_DRIVER_FAILED,
} from 'actions/types';

import {
	getListOrder,
	getListCn,
	getListOrderDetail,
	getListContainer,
	getTotalCn,
	assignDriver,
} from 'services/vendor';

export const getVendorListOrder = (orderType, date) => async dispatch => {
	const { dateFrom, dateTo } = date;
	try {
		dispatch({ type: GET_VENDOR_LIST_ORDER });
		const res = await getListOrder(orderType, dateFrom, dateTo);
		if (res.status === 200) {
			console.log(res.data.value.data);
			dispatch({
				type: GET_VENDOR_LIST_ORDER_SUCCESS,
				payload: res.data.value.data,
			});
		} else {
			console.log(res);
			dispatch({ type: GET_VENDOR_LIST_ORDER_FAILED, payload: res.data });
		}
	} catch (err) {
		dispatch({
			type: GET_VENDOR_LIST_ORDER_FAILED,
			payload: err.message,
		});
	}
};

export const getVendorListCn = payload => async dispatch => {
	const { type, status, dateFrom, dateTo } = payload;
	console.log(payload);
	try {
		dispatch({ type: GET_VENDOR_LIST_CN });
		const res = await getListCn(type, status, dateFrom, dateTo);
		if (res.status === 200) {
			dispatch({
				type: GET_VENDOR_LIST_CN_SUCCESS,
				payload: res.data.value.data,
			});
		} else {
			console.log('response', res);
			dispatch({ type: GET_VENDOR_LIST_CN_FAILED, payload: res.value });
		}
	} catch (err) {
		console.log(err.message);
		dispatch({ type: GET_VENDOR_LIST_CN_FAILED, payload: err.message });
	}
};

export const getVendorListOrderDetail = (orderId, orderType) => async dispatch => {
	try {
		dispatch({ type: GET_VENDOR_LIST_ORDER_DETAIL });
		const res = await getListOrderDetail(orderId, orderType);
		if (res.status === 200) {
			dispatch({
				type: GET_VENDOR_LIST_ORDER_DETAIL_SUCCESS,
				payload: res.data.value.data,
			});
		} else {
			// console.log('response', res);
			dispatch({
				type: GET_VENDOR_LIST_ORDER_DETAIL_FAILED,
				payload: res.data.errors,
			});
		}
	} catch (err) {
		console.log(err);
		dispatch({
			type: GET_VENDOR_LIST_ORDER_DETAIL_FAILED,
			payload: err.message,
		});
	}
};

export const getVendorUnpaidCn = (dateFrom, dateTo, status) => async dispatch => {
	// const { type } = payload;
	try {
		dispatch({ type: GET_VENDOR_UNPAID_CN });
		const res = await getTotalCn(dateFrom, dateTo, status);
		if (res.status === 200) {
			dispatch({ type: GET_VENDOR_UNPAID_CN_SUCCESS, payload: res.data.value.data });
		} else {
			console.log('response', res);
			dispatch({ type: GET_VENDOR_UNPAID_CN_FAILED });
		}
	} catch (err) {
		console.log(err);
		dispatch({ type: GET_VENDOR_UNPAID_CN_FAILED, payload: err.message });
	}
};

export const getVendorPaidCn = (dateFrom, dateTo, status) => async dispatch => {
	// const { type } = payload;
	try {
		dispatch({ type: GET_VENDOR_PAID_CN });
		const res = await getTotalCn(dateFrom, dateTo, status);
		if (res.status === 200) {
			dispatch({ type: GET_VENDOR_PAID_CN_SUCCESS, payload: res.data.value.data });
		} else {
			console.log('response', res);
			dispatch({ type: GET_VENDOR_PAID_CN_FAILED });
		}
	} catch (err) {
		console.log(err.message);
		dispatch({ type: GET_VENDOR_PAID_CN_FAILED, payload: err.message });
	}
};

export const getVendorListContainer = orderMgtDetailId => async dispatch => {
	try {
		dispatch({ type: GET_VENDOR_LIST_CONTAINER });
		const res = await getListContainer(orderMgtDetailId);
		if (res.status === 200) {
			dispatch({ type: GET_VENDOR_LIST_CONTAINER_SUCCESS, payload: res.data.value.data });
		} else {
			console.log(res.data);
			dispatch({ type: GET_VENDOR_LIST_CONTAINER_FAILED, payload: res.data });
		}
	} catch (err) {
		console.log(err.message);
		dispatch({ type: GET_VENDOR_LIST_CONTAINER_FAILED, payload: err.message });
	}
};

export const vendorAssignDriver = payload => async dispatch => {
	try {
		dispatch({ type: VENDOR_ASSIGN_DRIVER });
		const res = await assignDriver(payload);
		if (res.data.value.statusSuccess === 1) {
			dispatch({ type: VENDOR_ASSIGN_DRIVER_SUCCESS, message: res.data.value.messages });
		} else {
			dispatch({ type: VENDOR_ASSIGN_DRIVER_FAILED, message: res.data.value.messages });
		}
		console.log(res.data.value.statusSuccess);
		return res.data.value.statusSuccess === 1;
	} catch (err) {
		console.log(err.message);
		dispatch({ type: VENDOR_ASSIGN_DRIVER_FAILED, error: err.message });
	}
};
