import { SCROLLPOSITION, TYPEBOOKING, BOOKINGTRUCK, GETCONTAINER, ALLPORT, RESNEWORDER, TYPEEXPORIMPORT, REQUEST_SEND_EMAIL, SUCCESS_SEND_EMAIL, ERROR_SEND_EMAIL } from './types'
import { getContainerTypeListing, getAllPortService, getRateListing, postNewOrderTruck, requestScheduleApi, sendEmailBantuan } from 'services/home'
import { getPlaceId } from 'services/apiGoogleMap'
import { emailTo1, emailTo2 } from 'services/init'
import { containerType } from 'utils/contants';

export const setScrollposition = () => {
    return {
        type: SCROLLPOSITION,
        scrollposition: document.documentElement.scrollTop
    }
}

export const setTypeBooking = (typeBooking) => {
    return {
        type: TYPEBOOKING,
        typeBooking
    }
}

export const addBookingTruck = (bookingTruck) => {
    return {
        type: BOOKINGTRUCK,
        bookingTruck
    }
}

export const getContainer = () => async (dispatch, getState) => {
    const { addressA } = await getState().home.bookingTruck
    try {
        const detailAddres = await getPlaceId(addressA.place_id)
        const { lat, lng } = detailAddres
        const listContainer = await getContainerTypeListing()
        const constListContainer = await listContainer.map(value => value.containerTypeID)
        const reslistContainer = await getRateListing(constListContainer, lat, lng)
        const setContainerType = await reslistContainer.map(val => Object.assign(val, { containerType: containerType.filter(item => item.id === val.containerTypeID)[0].name }))
        dispatch({
            type: GETCONTAINER,
            listContainer: setContainerType
        })
        return true
    } catch (err) {
        dispatch({
            type: GETCONTAINER,
            listContainer: []
        })
        return true
    }
}

export const getAllPort = () => async dispatch => {
    try {
        const listPort = await getAllPortService()
        dispatch({
            type: ALLPORT,
            listPort
        })
    } catch (err) {
        dispatch({
            type: ALLPORT,
            listPort: []
        })
    }
}

export const newOrderTruck = ({ customerInfo }) => async (dispatch, getState) => {
    const { bookingTruck, listContainer } = getState().home
    const { addressB, addressA, date } = bookingTruck
    const { districtID } = addressB

    const containersMap = await listContainer.map(function (value) {
        if (value.priceAll) {
            return {
                containerTypeID: value.containerTypeID,
                desc: value.description,
                price: value.priceAll,
                qty: value.quantity,
                stuffingDate: date
            }
        } else {
            return {}
        }
    })


    const data = {
        customerInfo,
        truckOrder: {
            containers: containersMap.filter(val => val.containerTypeID),
            districtID: districtID,
            orderType: "EXPORT",
            tierID: "TIER-0001"
        },
    }

    try {
        const res = await postNewOrderTruck(data)
        dispatch({
            type: RESNEWORDER,
            resNewOrder: "SUCCESS",
            idBooking: res.data.value
        })
    } catch (err) {
        dispatch({
            type: RESNEWORDER,
            resNewOrder: "ERROR"
        })
    }
}

export const setTypeExporImport = (typeExportImport) => (dispatch) => {
    dispatch({
        type: TYPEEXPORIMPORT,
        typeExportImport
    })
}

export const requestSchedule = (customerInfo) => async (dispatch, getState) => {
    const { home, booking } = getState()
    const { typeBooking, typeExportImport, bookingTruck, listContainer } = home
    const { selected_origin, selected_destination, selectedDate, selectedContainer } = booking
    const { addressB, addressA, date } = bookingTruck
    const selectA = typeExportImport === 'import' ? addressB : addressA
    const selectB = typeExportImport === 'import' ? addressA : addressB
    const containersMap = await listContainer.map(function (value) {
        if (value.priceAll) {
            return {
                containerTypeID: value.containerType,
                desc: value.description,
                price: value.priceAll,
                qty: value.quantity,
                stuffingDate: date
            }
        }
        return
    })
    const data = {
        to1: emailTo1,
        to2: emailTo2,
        customerInfo,
        typeBooking,
        order: typeBooking === "freight" ?
            {
                orderType: "export",
                addressA: selected_origin,
                addressB: selected_destination,
                date: selectedDate,
                containers: selectedContainer
            }
            :
            {
                containers: await containersMap.filter(val => val !== undefined),
                addressA: selectA,
                addressB: selectB,
                orderType: typeExportImport,
                date
            }
    }
    dispatch({ type: REQUEST_SEND_EMAIL, requestSendEmail: true });
    try {
        await requestScheduleApi(data);
        dispatch({ type: SUCCESS_SEND_EMAIL, successSendEmail: true });
        dispatch({ type: REQUEST_SEND_EMAIL, requestSendEmail: false });
    } catch (err) {
        dispatch({ type: ERROR_SEND_EMAIL, errorSendEmail: true });
        dispatch({ type: REQUEST_SEND_EMAIL, requestSendEmail: false });
        console.log(err)
    }
};

export const sendEmailPusatBantuan = (data) => async (dispatch) => {
    const dataSend = Object.assign({
        to1: emailTo1,
        to2: emailTo2,
    }, data)

    dispatch({ type: REQUEST_SEND_EMAIL, requestSendEmail: true });
    try {
        await sendEmailBantuan(dataSend);
        dispatch({ type: SUCCESS_SEND_EMAIL, successSendEmail: true });
        dispatch({ type: REQUEST_SEND_EMAIL, requestSendEmail: false });
    } catch (err) {
        dispatch({ type: ERROR_SEND_EMAIL, errorSendEmail: true });
        dispatch({ type: REQUEST_SEND_EMAIL, requestSendEmail: false });
    }
};