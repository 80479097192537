import ReactGA from 'react-ga';

const initialize = () => ReactGA.initialize(
    [
        {
            trackingId: process.env.REACT_APP_ANALYTICS_LOGOL,
            gaOptions: {
                name: 'logol_v3'
            }
        },
        {
            trackingId: process.env.REACT_APP_ANALYTICS_EDI,
            gaOptions: {
                name: 'EDI'
            }
        }
    ]
);
const pageview = () => ReactGA.pageview(window.location.pathname + window.location.search, ['logol', 'EDI']);

export {
    initialize,
    pageview
}