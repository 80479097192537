import { getStorageData } from '../../services/Export/getStorageList'

export const GET_STORAGE_LIST_REQUEST = "GET_STORAGE_LIST_REQUEST"
export const GET_STORAGE_LIST_SUCCESS = "GET_STORAGE_LIST_SUCCESS"
export const GET_STORAGE_LIST_FAILURE = "GET_STORAGE_LIST_FAILURE"

const getStorageListRequest = () => {
    return {
      type: GET_STORAGE_LIST_REQUEST
    }
  }
  
  const getStorageListSuccess = storageListData => {
    return {
      type: GET_STORAGE_LIST_SUCCESS,
      storageListData
    }
  }
  
  const getStorageListFailure = () => {
    return {
      type: GET_STORAGE_LIST_FAILURE
    }
  }

export const getStorageList = (term) => async dispatch => {
    dispatch(getStorageListRequest())
    const response = await getStorageData(term)
    if (response) {
        dispatch(getStorageListSuccess(response))
        return true
    } else {
      dispatch(getStorageListFailure())
      return false
    }
  }