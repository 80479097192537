import {
    GET_DELETE_CART_REQUEST,
    GET_DELETE_CART_SUCCESS,
    GET_DELETE_CART_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        deletedCartData : {},
    },
    action
  ) => {
    switch (action.type) {
      case GET_DELETE_CART_REQUEST:
        return {
          ...state,
          getError:false
        };
      case GET_DELETE_CART_SUCCESS:
        return {
          ...state,
          getError:false,
          deletedCartData: action.deletedCartData
        };
      case GET_DELETE_CART_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };