import {
    SEARCH_DEPO_REQUEST,
    SEARCH_DEPO_SUCCESS,
    SEARCH_DEPO_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        searchDepoData : [],
    },
    action
  ) => {
    switch (action.type) {
      case SEARCH_DEPO_REQUEST:
        return {
          ...state,
          getError:false
        };
      case SEARCH_DEPO_SUCCESS:
        return {
          ...state,
          getError:false,
          searchDepoData: action.searchDepoData
        };
      case SEARCH_DEPO_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };