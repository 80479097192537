import axios from 'axios'
import { base } from '../init'
import { getCookie } from 'utils/cookies';

const token = getCookie('token');

export const getTruckDetailOrderList = async (orderID, sort, desc, terms) => {
    try {
        const response = await axios.get(`${base}/vendorOrder/getVendorOrderDetailList?orderManagementID=${orderID}&sort=${sort}&desc=${desc}&terms=${terms}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        })
        return response.data.value
    } catch (err) {
        return []
    }
};