export const base = process.env.REACT_APP_SERVICE_BASE;
export const callAPI = process.env.REACT_APP_API;
export const apiPic = process.env.REACT_APP_API_PIC;
export const apiFreight = process.env.REACT_APP_API_FREIGHT;
export const apiLocation = process.env.REACT_APP_API_INDONESIA_LOCATION;
export const sendEmailApi = process.env.REACT_APP_SEND_MAIL;
export const emailTo1 = process.env.REACT_APP_EMAIL_TO1;
export const emailTo2 = process.env.REACT_APP_EMAIL_TO2;
export const apiGoogleMap = process.env.REACT_APP_API_GOOGLE_MAP;
export const proxyHeroku = process.env.REACT_APP_PROXY_HEROKU;
export const gatepassScheduler = process.env.REACT_APP_GATEPASS_SECHEDULER;
export const vendorScheduler = process.env.REACT_APP_VENDOR_SCHEDULER;
