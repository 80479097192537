import update from 'immutability-helper'

export default function reducer (
    state = {
        loaded      : [],
        filtered    : [],
        list        : [],
        selected    : null,
        limit       : 15,
        current_page: 0,
        filters : {
            shipping_line: [],
            depart_date  : [],
            arrive_date  : [],
            duration     : [],
        }
    }, 
    action
){
    const _updateLoaded = (data, list) => {
        let updated = update(list, {$set: data})
        list        = updated
        return list
    }

    const _updateList = (data, list, offset = 0) => {
        let max      = offset < 1 ? state.limit: (offset + 1) * state.limit
        let modified = data.filter((d, index)  => index >= offset * state.limit && index < max)
        let updated  = update(list, {$set: modified})
        list         = updated
        return list
    }

    const _filter = (type, data, key) => {
        let {loaded, filters, list} = state
        let checked = type !== 'duration' ? data.filter(prop => prop.checked).map(prop => prop[key]) : data
        let updatedFilters = update(filters, {[type]: {$set: checked}})
        state.filters = updatedFilters
        
        let newFilters = Object.keys(state.filters).filter(obj => state.filters[obj].length > 0)

        let filtered = loaded 
        
        for(let i in newFilters){
            filtered = filtered.filter(obj => newFilters[i] !== 'duration' ? 
                                state.filters[newFilters[i]].indexOf(obj[newFilters[i]]) >= 0 :
                                obj[newFilters[i]] >= state.filters[newFilters[i]][0] && obj[newFilters[i]] <= state.filters[newFilters[i]][1]
                        )
        }

        let tempList  = _updateList(newFilters.length === 0 ? loaded : filtered, list)
        let updatedList = update(list, {$set: tempList})
        state.list = updatedList
        return filtered
    }

    switch (action.type) {
        case 'SCHEDULES_GET_DONE': {
            return {
                ...state,
                loaded : _updateLoaded(action.payload.prices, state.loaded),
                list   : _updateList(action.payload.prices, state.list),
            }
        }

        case 'SCHEDULES_SORT_DONE':{
            return {
                ...state,
                list: _updateList(action.payload, state.list)
            }
        }

        case 'SCHEDULES_FILTER_DONE':{
            return {
                ...state,
                filtered    : _filter(action.payload.type, action.payload.data, action.payload.key),
                current_page: 0
            }
        }

        case 'SCHEDULES_RESET_DONE':{
            return {
                ...state,
                loaded      : [],
                filtered    : [],
                list        : [],
                selected    : null,
                limit       : 15,
                current_page: 0,
                filters : {
                    shipping_line: [],
                    depart_date  : [],
                    arrive_date  : [],
                    duration     : [],
                }
            }
        }

        case 'SCHEDULE_SELECT':{
            return {
                ...state,
                selected: action.payload
            }
        }

        case 'SCHEDULE_PAGE':{
            return {
                ...state,
                list: _updateList(state.filtered.length > 0 ? state.filtered : state.loaded, state.list, action.payload.offset),
                current_page: action.payload.offset
            }
        }

        default:
            return state
    }
}