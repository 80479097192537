import {
    ADD_OR_UPDATE_CART_ITEM_REQUEST,
    ADD_OR_UPDATE_CART_ITEM_SUCCESS,
    ADD_OR_UPDATE_CART_ITEM_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        cartItemData : {},
    },
    action
  ) => {
    switch (action.type) {
      case ADD_OR_UPDATE_CART_ITEM_REQUEST:
        return {
          ...state,
          getError:false
        };
      case ADD_OR_UPDATE_CART_ITEM_SUCCESS:
        return {
          ...state,
          getError:false,
          cartItemData: action.cartItemData
        };
      case ADD_OR_UPDATE_CART_ITEM_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };