import { combineReducers } from 'redux';

import auth from './auth';
import home from './home';
import ports from './ports';
import booking from './booking';
import schedules from './schedules';
import idlocation from './idlocation';
import uploadFile from './uploadFile';
import sendEmail from './sendEmail';
import getOrderDetail from './TruckBookingList/getOrderList';
import getOrderInfoSummary from './TruckBookingList/getOrderInfoSummary';
import getOrderDetailListing from './TruckBookingList/getOrderDetailListing';
import requestGatePass from './TruckBookingList/requestGatePass';
import depo from './Export/searchDepo';
import factoryList from './Export/factoryList';
import containerTypeList from './Export/getContainerTypeListing';
import portList from './Export/portList';
import cartItem from './Export/addOrUpdateCartItem';
import createNewCart from './Export/newCart';
import additionalServices from './getAllServices';
import searchPortData from './Export/searchPort';
import newOrder from './Export/placeNewCustomerOrder';
import gatepassReducers from './Gatepass/gatepassReducers';
import deletedCart from './Export/deleteCartItem';
import vendorReducer from './Vendor/vendorReducers';
import driverReducers from './Vendor/driverReducers';
import vehicleReducers from './Vendor/vehicleReducers';
import newFactory from './Export/createFactory';
import destinationPicker from './Export/getDestinationPickerDs';
import storage from './Export/getStorageList';
import containerReducers from './Vendor/containerReducers';
import shippingData from './Export/shippingList';
import vesselSchedules from './vesselSchedules';

export default combineReducers({
	auth,
	home,
	ports,
	booking,
	schedules,
	idlocation,
	uploadFile,
	sendEmail,
	getOrderDetail,
	getOrderInfoSummary,
	getOrderDetailListing,
	requestGatePass,
	depo,
	factoryList,
	containerTypeList,
	portList,
	cartItem,
	createNewCart,
	additionalServices,
	searchPortData,
	newOrder,
	gatepassReducers,
	deletedCart,
	newFactory,
	destinationPicker,
	storage,
	vendorReducer,
	driverReducers,
	vehicleReducers,
	containerReducers,
	shippingData,
	vesselSchedules,
});
