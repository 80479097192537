import { uploadFiles } from 'services/uploadFile'

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE"

const uploadFileRequest = () => {
  return {
    type: UPLOAD_FILE_REQUEST
  }
}

const uploadFileSuccess = uploadedFileData => {
  return {
    type: UPLOAD_FILE_SUCCESS,
    uploadedFileData
  }
}

const uploadFileFailure = () => {
  return {
    type: UPLOAD_FILE_FAILURE
  }
}

export const uploadFile = (data) => async dispatch => {
  try {
    dispatch(uploadFileRequest())
    const response = await uploadFiles(data)
    dispatch(uploadFileSuccess(response))
    return true
  } catch (error) {
    dispatch(uploadFileFailure())
    return false
  }
}
