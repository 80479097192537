import {
	GET_VENDOR_LIST_VEHICLE,
	GET_VENDOR_LIST_VEHICLE_SUCCESS,
	GET_VENDOR_LIST_VEHICLE_FAILED,
	INSERT_VENDOR_VEHICLE,
	INSERT_VENDOR_VEHICLE_SUCCESS,
	INSERT_VENDOR_VEHICLE_FAILED,
	UPDATE_VENDOR_VEHICLE,
	UPDATE_VENDOR_VEHICLE_SUCCESS,
	UPDATE_VENDOR_VEHICLE_FAILED,
	DELETE_VENDOR_VEHICLE,
	DELETE_VENDOR_VEHICLE_SUCCESS,
	DELETE_VENDOR_VEHICLE_FAILED,
} from 'actions/types';

const initialState = {
	isLoading: false,
	listVehicle: [],
	error: '',
	message: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_VENDOR_LIST_VEHICLE:
			return {
				...state,
				isLoading: true,
			};
		case GET_VENDOR_LIST_VEHICLE_SUCCESS:
			return {
				...state,
				isLoading: false,
				listVehicle: action.payload,
			};
		case GET_VENDOR_LIST_VEHICLE_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case INSERT_VENDOR_VEHICLE:
			return {
				...state,
				isLoading: true,
			};
		case INSERT_VENDOR_VEHICLE_SUCCESS:
			return {
				...state,
				isLoading: false,
				listVehicle: state.listVehicle.concat(action.payload),
			};
		case INSERT_VENDOR_VEHICLE_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case UPDATE_VENDOR_VEHICLE:
			return {
				...state,
			};
		case UPDATE_VENDOR_VEHICLE_SUCCESS:
			console.log(action.vehicleNumber);
			const updatedVehicle = state.listVehicle.map(item => {
				if (item.vehicleNumber !== action.vehicleNumber) {
					return item;
				}
				return {
					...item,
					...action.vehicle,
				};
			});
			console.log(updatedVehicle);
			return {
				...state,
				listVehicle: updatedVehicle,
				message: action.message,
			};
		case UPDATE_VENDOR_VEHICLE_FAILED:
			return {
				...state,
				error: action.payload,
				message: action.message,
			};
		case DELETE_VENDOR_VEHICLE:
			return {
				...state,
			};
		case DELETE_VENDOR_VEHICLE_SUCCESS:
			console.log(action.vehicleNumber);
			return {
				...state,
				listVehicle: state.listVehicle.filter(
					item => item.vehicleNumber !== action.vehicleNumber
				),
				message: action.message,
			};
		case DELETE_VENDOR_VEHICLE_FAILED:
			return {
				...state,
				error: action.payload,
				message: action.message,
			};
		default:
			return state;
	}
};
