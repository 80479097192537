import { deleteCartItems } from '../../services/Export/deleteCartItem'

export const GET_DELETE_CART_REQUEST = "GET_FACTORY_LIST_REQUEST"
export const GET_DELETE_CART_SUCCESS = "GET_FACTORY_LIST_SUCCESS"
export const GET_DELETE_CART_FAILURE = "GET_FACTORY_LIST_FAILURE"

const getCartDeleteRequest = () => {
    return {
      type: GET_DELETE_CART_REQUEST
    }
  }
  
  const getCartDeleteSuccess = deletedCartData => {
    return {
      type: GET_DELETE_CART_SUCCESS,
      deletedCartData
    }
  }
  
  const getCartDeleteFailure = () => {
    return {
      type: GET_DELETE_CART_FAILURE
    }
  }

export const deleteCart = (cartData) => async dispatch => {
    dispatch(getCartDeleteRequest())
    const response = await deleteCartItems(cartData)
    if (response) {
        dispatch(getCartDeleteSuccess(response))
        return true
    } else {
      dispatch(getCartDeleteFailure())
      return false
    }
  }