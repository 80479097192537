export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';

export const TYPEEXPORIMPORT = 'TYPEEXPORIMPORT';
export const TYPEBOOKING = 'TYPEBOOKING';
export const SCROLLPOSITION = 'SCROLLPOSITION';
export const BOOKINGTRUCK = 'BOOKINGTRUCK';
export const GETCONTAINER = 'GETCONTAINER';
export const ALLPORT = 'ALLPORT';
export const RATECONTAINER = 'RATECONTAINER';
export const RESNEWORDER = 'RESNEWORDER';
export const SUCCESS_SEND_EMAIL = 'SUCCESS_SEND_EMAIL';
export const REQUEST_SEND_EMAIL = 'REQUEST_SEND_EMAIL';
export const ERROR_SEND_EMAIL = 'ERROR_SEND_EMAIL';

export const DATAPROVINSI = 'DATAPROVINSI';
export const DATAKOTA = 'DATAKOTA';
export const DATAKECAMATAN = 'DATAKECAMATAN';
export const DATAKELURAHAN = 'DATAKELURAHAN';

export const UPLOADNPWP = 'UPLOADNPWP';
export const REQUEST_LOADING = 'REQUEST_LOADING';

export const REQUEST_GATEPASS = 'REQUEST_GATEPASS';
export const REQUEST_GATEPASS_SUCCESS = 'REQUEST_GATEPASS_SUCCESS';
export const REQUEST_GATEPASS_FAILED = 'REQUEST_GATEPASS_FAILED';

export const VERIFY_GATEPASS = 'VERIFY_GATEPASS';
export const VERIFY_GATEPASS_CLEAR = 'VERIFY_GATEPASS_CLEAR';
export const VERIFY_GATEPASS_SUCCESS = 'VERIFY_GATEPASS_SUCCESS';
export const VERIFY_GATEPASS_FAILED = 'VERIFY_GATEPASS_FAILED';

export const UPLOAD_GATEPASS_DOCUMENT = 'UPLOAD_GATEPASS_DOCUMENT';
export const UPLOAD_GATEPASS_DOCUMENT_SUCCESS = 'UPLOAD_GATEPASS_DOCUMENT_SUCCESS';
export const UPLOAD_GATEPASS_DOCUMENT_FAILED = 'UPLOAD_GATEPASS_DOCUMENT_FAILED';

export const GET_ONBEHALF_NPWP = 'GET_ONBEHALF_NPWP';
export const GET_ONBEHALF_NPWP_SUCCESS = 'GET_ONBEHALF_NPWP_SUCCESS';
export const GET_ONBEHALF_NPWP_FAILED = 'GET_ONBEHALF_NPWP_FAILED';

export const CREATE_ONBEHALF_NPWP = 'CREATE_ONBEHALF_NPWP';
export const CREATE_ONBEHALF_NPWP_SUCCESS = 'CREATE_ONBEHALF_NPWP';
export const CREATE_ONBEHALF_NPWP_FAILED = 'CREATE_ONBEHALF_NPWP_FAILED';

export const GET_CUSTOM_CODE_DOC = 'GET_CUSTOM_CODE_DOC';
export const GET_CUSTOM_CODE_DOC_SUCCESS = 'GET_CUSTOM_CODE_DOC_SUCCESS';
export const GET_CUSTOM_CODE_DOC_FAILED = 'GET_CUSTOM_CODE_DOC_FAILED';

export const GET_VENDOR_LIST_ORDER = 'GET_VENDOR_LIST_ORDER';
export const GET_VENDOR_LIST_ORDER_SUCCESS = 'GET_VENDOR_LIST_ORDER_SUCCESS';
export const GET_VENDOR_LIST_ORDER_FAILED = 'GET_VENDOR_LIST_ORDER_FAILED';

export const GET_VENDOR_LIST_CN = 'GET_VENDOR_LIST_CN';
export const GET_VENDOR_LIST_CN_SUCCESS = 'GET_VENDOR_LIST_CN_SUCCESS';
export const GET_VENDOR_LIST_CN_FAILED = 'GET_VENDOR_LIST_CN_FAILED';

export const GET_VENDOR_LIST_ORDER_DETAIL = 'GET_VENDOR_LIST_ORDER_DETAIL';
export const GET_VENDOR_LIST_ORDER_DETAIL_SUCCESS =
	'GET_VENDOR_LIST_ORDER_DETAIL_SUCCESS';
export const GET_VENDOR_LIST_ORDER_DETAIL_FAILED = 'GET_VENDOR_LIST_ORDER_DETAIL_FAILED';

export const GET_VENDOR_UNPAID_CN = 'GET_VENDOR_UNPAID_CN';
export const GET_VENDOR_UNPAID_CN_SUCCESS = 'GET_VENDOR_UNPAID_CN_SUCCESS';
export const GET_VENDOR_UNPAID_CN_FAILED = 'GET_VENDOR_UNPAID_CN_FAILED';

export const GET_VENDOR_LIST_DRIVER = 'GET_VENDOR_LIST_DRIVER';
export const GET_VENDOR_LIST_DRIVER_SUCCESS = 'GET_VENDOR_LIST_DRIVER_SUCCESS';
export const GET_VENDOR_LIST_DRIVER_FAILED = 'GET_VENDOR_LIST_DRIVER_FAILED';

export const GET_VENDOR_LIST_VEHICLE = 'GET_VENDOR_LIST_VEHICLE';
export const GET_VENDOR_LIST_VEHICLE_SUCCESS = 'GET_VENDOR_LIST_VEHICLE_SUCCESS';
export const GET_VENDOR_LIST_VEHICLE_FAILED = 'GET_VENDOR_LIST_VEHICLE_FAILED';

export const INSERT_VENDOR_DRIVER = 'INSERT_VENDOR_DRIVER';
export const INSERT_VENDOR_DRIVER_SUCCESS = 'INSERT_VENDOR_DRIVER_SUCCESS';
export const INSERT_VENDOR_DRIVER_FAILED = 'INSERT_VENDOR_DRIVER_FAILED';

export const UPDATE_VENDOR_DRIVER = 'UPDATE_VENDOR_DRIVER';
export const UPDATE_VENDOR_DRIVER_SUCCESS = 'UPDATE_VENDOR_DRIVER_SUCCESS';
export const UPDATE_VENDOR_DRIVER_FAILED = 'UPDATE_VENDOR_DRIVER_FAILED';

export const DELETE_VENDOR_DRIVER = 'DELETE_VENDOR_DRIVER';
export const DELETE_VENDOR_DRIVER_SUCCESS = 'DELETE_VENDOR_DRIVER_SUCCESS';
export const DELETE_VENDOR_DRIVER_FAILED = 'DELETE_VENDOR_DRIVER_FAILED';

export const INSERT_VENDOR_VEHICLE = 'INSERT_VENDOR_VEHICLE';
export const INSERT_VENDOR_VEHICLE_SUCCESS = 'INSERT_VENDOR_VEHICLE_SUCCESS';
export const INSERT_VENDOR_VEHICLE_FAILED = 'INSERT_VENDOR_VEHICLE_FAILED';

export const UPDATE_VENDOR_VEHICLE = 'UPDATE_VENDOR_VEHICLE';
export const UPDATE_VENDOR_VEHICLE_SUCCESS = 'UPDATE_VENDOR_VEHICLE_SUCCESS';
export const UPDATE_VENDOR_VEHICLE_FAILED = 'UPDATE_VENDOR_VEHICLE_FAILED';

export const DELETE_VENDOR_VEHICLE = 'DELETE_VENDOR_VEHICLE';
export const DELETE_VENDOR_VEHICLE_SUCCESS = 'DELETE_VENDOR_VEHICLE_SUCCESS';
export const DELETE_VENDOR_VEHICLE_FAILED = 'DELETE_VENDOR_VEHICLE_FAILED';

export const GET_VENDOR_PAID_CN = 'GET_VENDOR_PAID_CN';
export const GET_VENDOR_PAID_CN_SUCCESS = 'GET_VENDOR_PAID_CN_SUCCESS';
export const GET_VENDOR_PAID_CN_FAILED = 'GET_VENDOR_PAID_CN_FAILED';

export const GET_VENDOR_LIST_CONTAINER = 'GET_VENDOR_LIST_CONTAINER';
export const GET_VENDOR_LIST_CONTAINER_SUCCESS = 'GET_VENDOR_LIST_CONTAINER_SUCCESS';
export const GET_VENDOR_LIST_CONTAINER_FAILED = 'GET_VENDOR_LIST_CONTAINER_FAILED';

export const VENDOR_ASSIGN_DRIVER = 'VENDOR_ASSIGN_DRIVER';
export const VENDOR_ASSIGN_DRIVER_SUCCESS = 'VENDOR_ASSIGN_DRIVER_SUCCESS';
export const VENDOR_ASSIGN_DRIVER_FAILED = 'VENDOR_ASSIGN_DRIVER_FAILED';

export const GET_VESSEL_SCHEDULES = 'GET_VESSEL_SCHEDULES';
export const GET_VESSEL_SCHEDULES_SUCCESS = 'GET_VESSEL_SCHEDULES_SUCCESS';
export const GET_VESSEL_SCHEDULES_FAILED = 'GET_VESSEL_SCHEDULES_FAILED';

export const GET_SHIPPING_LINES = 'GET_SHIPPING_LINES';
export const GET_SHIPPING_LINES_SUCCESS = 'GET_SHIPPING_LINES_SUCCESS';
export const GET_SHIPPING_LINES_FAILED = 'GET_SHIPPING_LINES_FAILED';
