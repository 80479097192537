import {
  UPLOAD_GATEPASS_DOCUMENT,
  UPLOAD_GATEPASS_DOCUMENT_FAILED,
  UPLOAD_GATEPASS_DOCUMENT_SUCCESS,

  VERIFY_GATEPASS,
  VERIFY_GATEPASS_SUCCESS,
  VERIFY_GATEPASS_FAILED,
  VERIFY_GATEPASS_CLEAR,

  REQUEST_GATEPASS,
  REQUEST_GATEPASS_FAILED,
  REQUEST_GATEPASS_SUCCESS,

  GET_ONBEHALF_NPWP,
  GET_ONBEHALF_NPWP_SUCCESS,
  GET_ONBEHALF_NPWP_FAILED,

  CREATE_ONBEHALF_NPWP,
  CREATE_ONBEHALF_NPWP_SUCCESS,
  CREATE_ONBEHALF_NPWP_FAILED,

  GET_CUSTOM_CODE_DOC,
  GET_CUSTOM_CODE_DOC_FAILED,
  GET_CUSTOM_CODE_DOC_SUCCESS,
} from 'actions/types';

const initialState = {
  isLoading: false,
  uploadGatepassResponse: {},
  uploadGatepassError: {},
  verifyGatepassResponse: {},
  verifyGatepassError: {},
  requestGatepassResponse: {},
  requestGatepassError: {},
  getOnBehalfNpwpResponse: [],
  getOnBehalfNpwpError: {},
  createOnBehalfNpwpResponse: {},
  createOnBehalfNpwpError: {},
  getCustomCodeDocResponse: {},
  getCustomCodeDocError: {}
};

export default (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // UPLOAD_GATEPASS_DOCUMENT
    case UPLOAD_GATEPASS_DOCUMENT:
      return {
        ...state,
        isLoading: true,
      };
    case UPLOAD_GATEPASS_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        uploadGatepassResponse: action.payload,
      }
    case UPLOAD_GATEPASS_DOCUMENT_FAILED:
      return {
        ...state,
        isLoading: false,
        uploadGatepassError: action.payload,
      }
    // VERIFY_GATEPASS
    case VERIFY_GATEPASS:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_GATEPASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verifyGatepassResponse: action.payload,
      }
    case VERIFY_GATEPASS_FAILED:
      return {
        ...state,
        isLoading: false,
        verifyGatepassError: action.payload,
      }
    case VERIFY_GATEPASS_CLEAR:
      return {
        ...state,
        isLoading: false,
        verifyGatepassError: {},
        verifyGatepassResponse: {},
      }
    // REQUEST_GATEPASS
    case REQUEST_GATEPASS:
      return {
        ...state,
        isLoading: true,
      };
    case REQUEST_GATEPASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestGatepassResponse: action.payload,
      }
    case REQUEST_GATEPASS_FAILED:
      return {
        ...state,
        isLoading: false,
        requestGatepassError: action.payload,
      }
    // GET_ONBEHALF_NPWP
    case GET_ONBEHALF_NPWP:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ONBEHALF_NPWP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getOnBehalfNpwpResponse: action.payload,
      }
    case GET_ONBEHALF_NPWP_FAILED:
      return {
        ...state,
        isLoading: false,
        getOnBehalfNpwpError: action.payload,
      }
    // CREATE_ONBEHALF_NPWP
    case CREATE_ONBEHALF_NPWP:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_ONBEHALF_NPWP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createOnBehalfNpwpResponse: action.payload,
      }
    case CREATE_ONBEHALF_NPWP_FAILED:
      return {
        ...state,
        isLoading: false,
        createOnBehalfNpwpError: action.payload,
      }
    // GET_CUSTOM_CODE_DOC
    case GET_CUSTOM_CODE_DOC:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CUSTOM_CODE_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getCustomCodeDocResponse: action.payload,
      }
    case GET_CUSTOM_CODE_DOC_FAILED:
      return {
        ...state,
        isLoading: false,
        getCustomCodeDocError: action.payload,
      }
    default:
      return state;
  }
};
