import React from 'react'

import favicon from 'assets/images/favicon.png'

export default function loadingLoad() {
    return (
        <div className="wrepper-loading">
            <div className="favicon">
                <img alt={"promo.title"} src={favicon} />
            </div>
        </div>
    )
}