import {
    CREATE_NEW_ORDER_REQUEST,
    CREATE_NEW_ORDER_SUCCESS,
    CREATE_NEW_ORDER_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        newCustomerOrder : {},
        orderCreated : false
    },
    action
  ) => {
    switch (action.type) {
      case CREATE_NEW_ORDER_REQUEST:
        return {
          ...state,
          getError:false,
          orderCreated : false
        };
      case CREATE_NEW_ORDER_SUCCESS:
        return {
          ...state,
          getError:false,
          newCustomerOrder: action.newCustomerOrder,
          orderCreated: true
        };
      case CREATE_NEW_ORDER_FAILURE:
          return {
          ...state,
          getError:true,
          orderCreated : false
        };     
      default:
        return state;
    }
  };