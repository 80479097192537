import axios from "services/axios";
import { gatepassScheduler } from './init';

export const requestGatepassServices = (payload) => axios.post("Gatepass/Ext/RequestGatepass", payload, {
  // baseURL: gatepassScheduler,
  headers: {
    'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyIiwiZXhwIjoxOTA1ODI5NjkyLCJpYXQiOjE1OTA0Njk2OTIsIm5iZiI6MTU5MDQ2OTY5MiwiY2xpZW50SUQiOjIsImNsaWVudE5hbWUiOiJObGUiLCJqdGkiOiJlOTBkNjc5ZC05MzMyLTRjNmQtOTM4ZC1iZmZkZGIxMmUwNzYifQ.9pE4NTd9GQbM4akAXjtXV4ZCiOealg7dYBcIldm2ZPoO_Aww-dXjRxy6NPl2Ul8Vur3sOSQqD0X2PnVDJnANow',
  },
});
export const verifyGatepassServices = (payload) => axios.post("Gatepass/Ext/Verify", payload, {
  // baseURL: gatepassScheduler,
  headers: {
    'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyIiwiZXhwIjoxOTA1ODI5NjkyLCJpYXQiOjE1OTA0Njk2OTIsIm5iZiI6MTU5MDQ2OTY5MiwiY2xpZW50SUQiOjIsImNsaWVudE5hbWUiOiJObGUiLCJqdGkiOiJlOTBkNjc5ZC05MzMyLTRjNmQtOTM4ZC1iZmZkZGIxMmUwNzYifQ.9pE4NTd9GQbM4akAXjtXV4ZCiOealg7dYBcIldm2ZPoO_Aww-dXjRxy6NPl2Ul8Vur3sOSQqD0X2PnVDJnANow',
  },
});

export const getOnBehalfNpwpServices = () => axios.get('getOnBehalfNpwp', {
  headers: {
    'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyIiwiZXhwIjoxOTA1ODI5NjkyLCJpYXQiOjE1OTA0Njk2OTIsIm5iZiI6MTU5MDQ2OTY5MiwiY2xpZW50SUQiOjIsImNsaWVudE5hbWUiOiJObGUiLCJqdGkiOiJlOTBkNjc5ZC05MzMyLTRjNmQtOTM4ZC1iZmZkZGIxMmUwNzYifQ.9pE4NTd9GQbM4akAXjtXV4ZCiOealg7dYBcIldm2ZPoO_Aww-dXjRxy6NPl2Ul8Vur3sOSQqD0X2PnVDJnANow',
  },
});

export const createOnBehalfNpwpServices = (payload) => axios.post('getOnBehalfNpwp', payload, {
  headers: {
    'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyIiwiZXhwIjoxOTA1ODI5NjkyLCJpYXQiOjE1OTA0Njk2OTIsIm5iZiI6MTU5MDQ2OTY5MiwiY2xpZW50SUQiOjIsImNsaWVudE5hbWUiOiJObGUiLCJqdGkiOiJlOTBkNjc5ZC05MzMyLTRjNmQtOTM4ZC1iZmZkZGIxMmUwNzYifQ.9pE4NTd9GQbM4akAXjtXV4ZCiOealg7dYBcIldm2ZPoO_Aww-dXjRxy6NPl2Ul8Vur3sOSQqD0X2PnVDJnANow',
  },
});

export const getCustomCodeDocServices = () => axios.get('Gatepass/getCustomCodeDoc', {
  baseURL: gatepassScheduler,
});