import update from "immutability-helper";

export default function reducer(
  state = {
    selected_origin: null,
    selected_destination: null,
    selectedDate: null,
    selectedContainer: null,
  },
  action
) {
  switch (action.type) {
    case "SEARCH_SCHEDULES": {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
