import { getTruckOrderListingData } from '../../services/TruckBookingList/getOrderList'
import { getVendorOrderDetailList } from 'services/TruckBookingList/getVendorOrderDetailList'

export const GET_ORDER_LISTING_REQUEST = "GET_ORDER_LISTING_REQUEST"
export const GET_ORDER_LISTING_SUCCESS = "GET_ORDER_LISTING_SUCCESS"
export const GET_ORDER_LISTING_FAILURE = "GET_ORDER_LISTING_FAILURE"

const getOrderListingRequest = () => {
  return {
    type: GET_ORDER_LISTING_REQUEST
  }
}

const getOrderListingSuccess = orderListData => {
  return {
    type: GET_ORDER_LISTING_SUCCESS,
    orderListData
  }
}

const getOrderListingFailure = () => {
  return {
    type: GET_ORDER_LISTING_FAILURE
  }
}

export const getOrderListData = (type, sort, desc, terms) => async dispatch => {
  dispatch(getOrderListingRequest())
  const params = `?type=${type}&sort=${sort}&desc=${desc}&terms=${terms}`
  const response = await getTruckOrderListingData(params)
  console.log('response === ', response)
  if (response.data.value) {
    dispatch(getOrderListingSuccess(response.data.value))
    return true
  } else {
    dispatch(getOrderListingFailure())
    return false
  }
}

export const getOrderListMonitor = (params) => async dispatch => {
  const response = await getVendorOrderDetailList(params)
  if (response.data.value) {
    dispatch(getOrderListingSuccess(response.data.value))
    return true
  } else {
    dispatch(getOrderListingFailure())
    return false
  }
}