class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static async authenticateUser(token) {
    return new Promise((resolve, reject) => {
      localStorage.setItem("jwt", token);
      resolve(true);
    });
  }

  /**
   * Authenticate a google user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static async authenticateGoogleUser(token) {
    return new Promise((resolve, reject) => {
      localStorage.setItem("g-auth-token", token);
      resolve(true);
    });
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isAuthenticated() {
    return new Promise((resolve, reject) => {
      resolve(
        localStorage.getItem("jwt") !== null ||
          localStorage.getItem("g-auth-token") !== null
      );
    });
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static async deauthenticateUser() {
    return new Promise((resolve, reject) => {
      localStorage.removeItem("jwt");
      localStorage.removeItem("g-auth-token");
      resolve(true);
    });
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */
  static getToken() {
    return localStorage.getItem("jwt") || localStorage.getItem("g-auth-token");
  }

  /**
   * Get google token value.
   *
   * @returns {string}
   */
  static getGoogleToken() {
    return localStorage.getItem("g-auth-token");
  }

  static isSuperUser() {
    return localStorage.getItem("superuser") !== "false";
  }

  static setUser(user) {
    localStorage.setItem("current_user", JSON.stringify(user));
  }

  static getUser() {
    return localStorage.getItem("current_user");
  }

  static test() {
    console.log("tessssssssssssssssssss");
  }
}

export default Auth;
