import { getProvinsi, getKota, getKecamatan, getKelurahan } from 'services/IdLocation'
import { DATAPROVINSI, DATAKOTA, DATAKECAMATAN, DATAKELURAHAN } from './types'

export const actionProvinsi = () => async dispatch => {
    try {
        const dataProvinsi = await getProvinsi()
        dispatch({ type: DATAPROVINSI, dataProvinsi: dataProvinsi.data.provinsi })
    } catch (error) {
        console.log(error)
    }
}

export const actionKota = (id) => async dispatch => {
    try {
        if (id) {
            const dataKota = await getKota(id)
            const selectData = await dataKota.data.kota_kabupaten.map(val => {
                let textArray = val.nama.split(" ")
                let textTow = textArray[2] ? textArray[2] : ""
                return { nama: textArray[1] + " " + textTow, id: val.id }
            })
            dispatch({ type: DATAKOTA, dataKota: selectData })
        }
    } catch (error) {
        console.log(error)
    }
}

export const actionKecamatan = (id) => async dispatch => {
    try {
        if (id) {
            const dataKecamatan = await getKecamatan(id)
            dispatch({ type: DATAKECAMATAN, dataKecamatan: dataKecamatan.data.kecamatan })
        }
    } catch (error) {
        console.log(error)
    }
}


export const actionKelurahan = (id) => async dispatch => {
    try {
        if (id) {
            const dataKelurahan = await getKelurahan(id)
            dispatch({ type: DATAKELURAHAN, dataKelurahan: dataKelurahan.data.kelurahan })
        }
    } catch (error) {
        console.log(error)
    }
}