import axios from 'services/axios';
import axiosBase from 'axios';
import { sendEmailApi, proxyHeroku, base } from '../init';

export const getContainerTypeListing = async () => {
	try {
		const response = await axios.get(`/marketplace/public/getContainerTypeListing`);
		return response.data.value;
	} catch (err) {
		return [];
	}
};

export const getAllPortService = async () => {
	try {
		const response = await axiosBase.get(`${base}marketplace/public/searchPort`)
		return response.data.value;
	} catch (err) {
		return [];
	}
};

export const getRateListing = async (containerTypeIDs, lat, lng) => {
	try {
		const response = await axios.post(`/truckRate/public/fetchTruckingRate`, {
			containerTypeIDs: containerTypeIDs,
			lat,
			lng,
			tierID: 'TIER-0001',
		});
		return response.data.value;
	} catch (err) {
		return {};
	}
};

export const postNewOrderTruck = async data => {
	return axios.post(`/marketplace/public/placeNewCustomerOrder`, data);
};

export const requestScheduleApi = async data => {
	return axiosBase.post(`${sendEmailApi}/post`, data);
};

export const sendEmailBantuan = async data => {
	return axiosBase.post(`${sendEmailApi}/bantuan`, data);
};
