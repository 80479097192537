import { getTruckDetailOrderList } from '../../services/TruckBookingList/getOrderDetailListing'

export const GET_TRUCK_ORDER_DETAIL_LIST_REQUEST = "GET_TRUCK_ORDER_DETAIL_LIST_REQUEST"
export const GET_TRUCK_ORDER_DETAIL_LIST_SUCCESS  = "GET_TRUCK_ORDER_DETAIL_LIST_SUCCESS"
export const GET_TRUCK_ORDER_DETAIL_LIST_FAILURE  = "GET_TRUCK_ORDER_DETAIL_LIST_FAILURE"

const getTruckDetailOrderRequest = () => {
    return {
      type: GET_TRUCK_ORDER_DETAIL_LIST_REQUEST
    }
  }
  
  const getTruckDetailOrderSuccess = truckDetailOrderData => {
    return {
      type: GET_TRUCK_ORDER_DETAIL_LIST_SUCCESS,
      truckDetailOrderData
    }
  }
  
  const getTruckDetailOrderFailure = () => {
    return {
      type: GET_TRUCK_ORDER_DETAIL_LIST_FAILURE
    }
  }

export const getTruckDetailOrderData = (orderID, sort, desc, terms) => async dispatch => {
    dispatch(getTruckDetailOrderRequest())
    const response = await getTruckDetailOrderList(orderID, sort, desc, terms)
    if (response) {
        dispatch(getTruckDetailOrderSuccess(response))
        return true
    } else {
      dispatch(getTruckDetailOrderFailure())
      return false
    }
  }