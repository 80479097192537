import axios from 'axios';
import Auth from 'utils/auth';
import { apiFreight } from 'services/init';

const AUTH_TOKEN = Auth.getToken();

export const api = axios.create({
	timeout: 10000,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Access-Control-Allow-Origin': '*',
	},
	baseURL: apiFreight,
});

// api.defaults.headers.common["Authorization"] = AUTH_TOKEN;
