import axios from 'axios'
import { base } from './init'
import { getCookie } from 'utils/cookies';

const token = getCookie('token');

export const getAdditionalService = async () => {
    try {
        const response = await axios.get(`${base}/serviceMaster/public/getAll`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (err) {
        return []
    }
};