import axios from 'axios'
import { base } from '../init'
import { getCookie } from 'utils/cookies';

const token = getCookie('token');

export const updateOrAddCartItems = async (carData) => {
    try {
        const response = await axios.post(`${base}/cart/public/addOrUpdateCartItem`, carData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (err) {
        return []
    }
};

export const getCartData = async (cartID) => {
    try {
        const response = await axios.get(`${base}/cart/public/getCart?cartID=${cartID}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (err) {
        return []
    }
};