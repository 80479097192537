import { getDestinationPickerID } from '../../services/Export/getDestinationPickerDs'

export const GET_DESTINATION_PICKER_REQUEST = "GET_DESTINATION_PICKER_REQUEST"
export const GET_DESTINATION_PICKER_SUCCESS  = "GET_DESTINATION_PICKER_SUCCESS"
export const GET_DESTINATION_PICKER_FAILURE  = "GET_DESTINATION_PICKER_FAILURE"

const getDestinationPickerRequest = () => {
    return {
      type: GET_DESTINATION_PICKER_REQUEST
    }
  }
  
  const getDestinationPickerSuccess = destinationPickerIds => {
    return {
      type: GET_DESTINATION_PICKER_SUCCESS,
      destinationPickerIds
    }
  }
  
  const getDestinationPickerFailure = () => {
    return {
      type: GET_DESTINATION_PICKER_FAILURE
    }
  }

export const getDestinationPicker = (orderType, dest1, dest2) => async dispatch => {
    dispatch(getDestinationPickerRequest())
    const params = `?orderType=${orderType}&dest1=${dest1}&dest2=${dest2}`
    const response = await getDestinationPickerID(params)
    if (response) {
        dispatch(getDestinationPickerSuccess(response.data.value))
        return true
    } else {
      dispatch(getDestinationPickerFailure())
      return false
    }
  }