import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'

import en from './locales/en_EN.json'
import id from './locales/id_ID.json'

const options = {
  order: ['querystring', 'localStorage'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  checkWhitelist: true
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    detection: options,
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },

    resources: {
      en: {
        translations: en
      },
      id: {
        translations: id
      },
    },
    ns: ['translations'],
    defaultNS: 'translations'
  });

export default i18n