import axios from 'axios'
import { base } from '../init'
import { getCookie } from 'utils/cookies';

const token = getCookie('token');

export const createNewCustomerOrder = async (order) => {
    console.log('order === ', JSON.stringify(order))
   try {
        const response = await axios.post(`${base}/marketplace/placeOrder`, order, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        })
        console.log('new order created === ', response)
        return response.data
    } catch (err) {
        return []
    }
};