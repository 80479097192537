import {
	GET_VENDOR_LIST_CONTAINER,
	GET_VENDOR_LIST_CONTAINER_SUCCESS,
	GET_VENDOR_LIST_CONTAINER_FAILED,
} from 'actions/types';

const initialState = {
	listContainer: [],
	isLoading: false,
	error: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_VENDOR_LIST_CONTAINER:
			return {
				...state,
				isLoading: true,
			};
		case GET_VENDOR_LIST_CONTAINER_SUCCESS:
			return {
				...state,
				isLoading: false,
				listContainer: action.payload,
			};
		case GET_VENDOR_LIST_CONTAINER_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
