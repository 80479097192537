import {
  GET_VESSEL_SCHEDULES,
  GET_VESSEL_SCHEDULES_SUCCESS,
  GET_VESSEL_SCHEDULES_FAILED,
  GET_SHIPPING_LINES,
  GET_SHIPPING_LINES_SUCCESS,
  GET_SHIPPING_LINES_FAILED,
} from "actions/types";

const initialState = {
  schedules: [],
  shippingLines: [],
  elements: 0,
  pages: 0,
  isLoading: false,
  loadingSL: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_VESSEL_SCHEDULES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_VESSEL_SCHEDULES_SUCCESS:
      const schedules = action.payload.result;
      for (let key in schedules) {
        for (let ind in schedules[key].routingDetails) {
          schedules[key].routingDetails[ind].originCountryFlag =
            schedules[key].originCountryFlag;
          schedules[key].routingDetails[ind].destinationCountryFlag =
            schedules[key].destinationCountryFlag;
        }
      }
      return {
        ...state,
        isLoading: false,
        schedules: schedules,
        elements: parseInt(action.payload.elements),
        pages: parseInt(action.payload.pages),
      };
    case GET_VESSEL_SCHEDULES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        schedules:[]
      };
    case GET_SHIPPING_LINES:
      return {
        ...state,
        loadingSL: true,
      };
    case GET_SHIPPING_LINES_SUCCESS:
      return {
        ...state,
        loadingSL: false,
        shippingLines: action.payload,
      };
    case GET_SHIPPING_LINES_FAILED:
      return {
        ...state,
        loadingSl: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
