import {
	GET_VENDOR_LIST_VEHICLE,
	GET_VENDOR_LIST_VEHICLE_SUCCESS,
	GET_VENDOR_LIST_VEHICLE_FAILED,
	INSERT_VENDOR_VEHICLE,
	INSERT_VENDOR_VEHICLE_SUCCESS,
	INSERT_VENDOR_VEHICLE_FAILED,
	UPDATE_VENDOR_VEHICLE,
	UPDATE_VENDOR_VEHICLE_SUCCESS,
	UPDATE_VENDOR_VEHICLE_FAILED,
	DELETE_VENDOR_VEHICLE,
	DELETE_VENDOR_VEHICLE_SUCCESS,
	DELETE_VENDOR_VEHICLE_FAILED,
} from 'actions/types';
import { getCookie } from 'utils/cookies';

import {
	getListVehicle,
	insertVehicle,
	updateVehicle,
	deleteVehicle,
} from 'services/vendor';

export const getVendorListVehicle = () => async dispatch => {
	try {
		dispatch({ type: GET_VENDOR_LIST_VEHICLE });
		const res = await getListVehicle();
		if (res.status === 200) {
			dispatch({ type: GET_VENDOR_LIST_VEHICLE_SUCCESS, payload: res.data.value });
		} else {
			console.log(res.data);
			dispatch({ type: GET_VENDOR_LIST_VEHICLE_FAILED, payload: res.data });
		}
	} catch (err) {
		console.log(err.message);
		dispatch({ type: GET_VENDOR_LIST_VEHICLE_FAILED, payload: err.message });
	}
};

export const insertVendorVehicle = payload => async dispatch => {
	try {
		dispatch({ type: INSERT_VENDOR_VEHICLE });
		const res = await insertVehicle(payload);
		console.log(res);
		if (res.data.value.statusSuccess === 1) {
			dispatch({
				type: INSERT_VENDOR_VEHICLE_SUCCESS,
				payload: payload,
				message: res.data.value.messages,
			});
		} else {
			dispatch({ type: INSERT_VENDOR_VEHICLE_FAILED, payload: res.data.value.messages });
		}
		return res.data.value.statusSuccess === 1;
	} catch (err) {
		console.log(err.message);
		dispatch({ type: INSERT_VENDOR_VEHICLE_FAILED, payload: err.message });
	}
};

export const updateVendorVehicle = payload => async dispatch => {
	const { vehicleNumber } = payload;
	try {
		dispatch({ type: UPDATE_VENDOR_VEHICLE });
		const res = await updateVehicle(payload);
		if (res.data.value.statusSuccess === 1) {
			dispatch({
				type: UPDATE_VENDOR_VEHICLE_SUCCESS,
				vehicle: payload,
				message: res.data.value.messages,
				vehicleNumber: vehicleNumber,
			});
		} else {
			dispatch({ type: UPDATE_VENDOR_VEHICLE_FAILED, message: res.data.value.messages });
		}
		return res.data.value.statusSuccess === 1;
	} catch (err) {
		console.log(err.message);
		dispatch({ type: UPDATE_VENDOR_VEHICLE_FAILED, payload: err.message });
	}
};

export const deleteVendorVehicle = vehicleNumber => async dispatch => {
	const vendorId = getCookie('userId');
	const payload = { vehicleNumber: vehicleNumber, vendorId: vendorId };
	try {
		dispatch({ type: DELETE_VENDOR_VEHICLE });
		const res = await deleteVehicle(payload);
		if (res.status === 200) {
			dispatch({
				type: DELETE_VENDOR_VEHICLE_SUCCESS,
				vehicle: res.data,
				message: res.data.value.messages,
				vehicleNumber: vehicleNumber,
			});
		} else {
			dispatch({
				type: DELETE_VENDOR_VEHICLE_FAILED,
				payload: res.data,
				message: res.data.value.messages,
			});
		}
		return res.status === 200;
	} catch (err) {
		console.log(err.message);
		dispatch({ type: DELETE_VENDOR_VEHICLE_FAILED, payload: err.message });
	}
};
