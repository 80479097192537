import { getShippingListData } from '../../services/Export/shippingList'

export const GET_SHIPPING_LIST_REQUEST = "GET_SHIPPING_LIST_REQUEST"
export const GET_SHIPPING_LIST_SUCCESS = "GET_SHIPPING_LIST_SUCCESS"
export const GET_SHIPPING_LIST_FAILURE = "GET_SHIPPING_LIST_FAILURE"

const getShippingListRequest = () => {
    return {
      type: GET_SHIPPING_LIST_REQUEST
    }
  }
  
  const getShippingListSuccess = shippingListData => {
    return {
      type: GET_SHIPPING_LIST_SUCCESS,
      shippingListData
    }
  }
  
  const getShippingListFailure = () => {
    return {
      type: GET_SHIPPING_LIST_FAILURE
    }
  }

export const getShippingList = (term) => async dispatch => {
    dispatch(getShippingListRequest())
    const response = await getShippingListData(term)
    if (response) {
        dispatch(getShippingListSuccess(response))
        return true
    } else {
      dispatch(getShippingListFailure())
      return false
    }
  }