import { createNewCart } from '../../services/Export/newCart'

export const CREATE_NEW_CART_REQUEST = "CREATE_NEW_CART_REQUEST"
export const CREATE_NEW_CART_SUCCESS = "CREATE_NEW_CART_SUCCESS"
export const CREATE_NEW_CART_FAILURE = "CREATE_NEW_CART_FAILURE"

const createNewCartRequest = () => {
    return {
      type: CREATE_NEW_CART_REQUEST
    }
  }
  
  const createNewCartSuccess = newCart => {
    return {
      type: CREATE_NEW_CART_SUCCESS,
      newCart
    }
  }
  
  const createNewCartFailure = () => {
    return {
      type: CREATE_NEW_CART_FAILURE
    }
  }

export const addNewCart = (carData) => async dispatch => {
    dispatch(createNewCartRequest())
    const response = await createNewCart(carData)
    if (response) {
        dispatch(createNewCartSuccess(response))
        return true
    } else {
      dispatch(createNewCartFailure())
      return false
    }
  }