import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE
} from "../actions";

export default (
  state = {
    getError: false,
    uploadedFileData: [],
  },
  action
) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        getError: false
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        getError: false,
        uploadedFileData: action.uploadedFileData
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        getError: true,
      };
    default:
      return state;
  }
};
