import axios from 'axios'
import { apiGoogleMap, proxyHeroku } from './init'

const ifProxyDev = proxyHeroku

export const autocompletePlace = async (text) => {
    try {
        const { data } = await axios.get(`${apiGoogleMap}/place?input=${text}`)
        return data.value.predictions
    } catch (err) {
        return err
    }
}

export const getPlaceId = async (place_id) => {
    try {
        const { data } = await axios.get(`${apiGoogleMap}/geocode?placeID=${place_id}`)
        return data.value.results[0].geometry.location
    } catch (err) {
        return err
    }
}