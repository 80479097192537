import {
    GET_SHIPPING_LIST_REQUEST,
    GET_SHIPPING_LIST_SUCCESS,
    GET_SHIPPING_LIST_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        shippingListData : [],
    },
    action
  ) => {
    switch (action.type) {
      case GET_SHIPPING_LIST_REQUEST:
        return {
          ...state,
          getError:false
        };
      case GET_SHIPPING_LIST_SUCCESS:
        return {
          ...state,
          getError:false,
          shippingListData: action.shippingListData
        };
      case GET_SHIPPING_LIST_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };