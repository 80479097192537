import {
	GET_VENDOR_LIST_DRIVER,
	GET_VENDOR_LIST_DRIVER_SUCCESS,
	GET_VENDOR_LIST_DRIVER_FAILED,
	INSERT_VENDOR_DRIVER,
	INSERT_VENDOR_DRIVER_SUCCESS,
	INSERT_VENDOR_DRIVER_FAILED,
	UPDATE_VENDOR_DRIVER,
	UPDATE_VENDOR_DRIVER_SUCCESS,
	UPDATE_VENDOR_VEHICLE_FAILED,
	DELETE_VENDOR_DRIVER,
	DELETE_VENDOR_DRIVER_SUCCESS,
	DELETE_VENDOR_DRIVER_FAILED,
} from 'actions/types';

const initialState = {
	isLoading: false,
	listDriver: [],
	error: '',
	message: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_VENDOR_LIST_DRIVER:
			return {
				...state,
				isLoading: true,
			};
		case GET_VENDOR_LIST_DRIVER_SUCCESS:
			return {
				...state,
				isLoading: false,
				listDriver: action.payload,
			};
		case GET_VENDOR_LIST_DRIVER_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case INSERT_VENDOR_DRIVER:
			return {
				...state,
				isLoading: true,
			};
		case INSERT_VENDOR_DRIVER_SUCCESS:
			return {
				...state,
				isLoading: false,
				listDriver: state.listDriver.concat(action.payload),
				message: action.message,
			};
		case INSERT_VENDOR_DRIVER_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				message: action.message,
			};
		case UPDATE_VENDOR_DRIVER:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_VENDOR_DRIVER_SUCCESS:
			const updatedDriver = state.listDriver.map(item => {
				if (item.driverID !== action.driverId) {
					return item;
				}
				return {
					...item,
					...action.driver,
				};
			});
			return {
				...state,
				isLoading: false,
				listDriver: updatedDriver,
				message: action.message,
			};
		case UPDATE_VENDOR_VEHICLE_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				message: action.message,
			};
		case DELETE_VENDOR_DRIVER:
			return {
				...state,
				isLoading: true,
			};
		case DELETE_VENDOR_DRIVER_SUCCESS:
			const newDriver = state.listDriver.filter(
				driver => driver.DriverID !== action.driverId
			);
			console.log(newDriver);
			return {
				...state,
				isLoading: false,
				listDriver: newDriver,
				message: action.message,
			};
		case DELETE_VENDOR_DRIVER_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				message: action.message,
			};
		default:
			return state;
	}
};
