import {
    GET_ORDER_LISTING_REQUEST,
    GET_ORDER_LISTING_SUCCESS,
    GET_ORDER_LISTING_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        getTruckOrderDetail : [],
    },
    action
  ) => {
    switch (action.type) {
      case GET_ORDER_LISTING_REQUEST:
        return {
          ...state,
          getError:false
        };
      case GET_ORDER_LISTING_SUCCESS:
        return {
          ...state,
          getError:false,
          getTruckOrderDetail: action.orderListData
        };
      case GET_ORDER_LISTING_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };