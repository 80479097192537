import {
    GET_CREATE_FACTORY_REQUEST,
    GET_CREATE_FACTORY_SUCCESS,
    GET_CREATE_FACTORY_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        factoryCreated : {},
    },
    action
  ) => {
    switch (action.type) {
      case GET_CREATE_FACTORY_REQUEST:
        return {
          ...state,
          getError:false
        };
      case GET_CREATE_FACTORY_SUCCESS:
        return {
          ...state,
          getError:false,
          factoryCreated: action.factoryCreated
        };
      case GET_CREATE_FACTORY_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };