import axios from 'axios'
import { base } from './init'
import { getCookie } from 'utils/cookies';

const token = getCookie('token');

export const uploadFiles = async (data) => {
    try {
        const response = await axios.post(`${base}/file/public/uploadFile`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
                // 'Content-Type': 'multipart/form-data'
            },
        })
        return response.data.value
    } catch (err) {
        return []
    }
};

