import {
    REQUEST_GATEPASS_REQUEST,
    REQUEST_GATEPASS_SUCCESS,
    REQUEST_GATEPASS_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        gatePassData : [],
    },
    action
  ) => {
    switch (action.type) {
      case REQUEST_GATEPASS_REQUEST:
        return {
          ...state,
          getError:false
        };
      case REQUEST_GATEPASS_SUCCESS:
        return {
          ...state,
          getError:false,
          gatePassData: action.gatePassData
        };
      case REQUEST_GATEPASS_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };