import {
    GET_FACTORY_LIST_REQUEST,
    GET_FACTORY_LIST_SUCCESS,
    GET_FACTORY_LIST_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        factoryListData : [],
    },
    action
  ) => {
    switch (action.type) {
      case GET_FACTORY_LIST_REQUEST:
        return {
          ...state,
          getError:false
        };
      case GET_FACTORY_LIST_SUCCESS:
        return {
          ...state,
          getError:false,
          factoryListData: action.factoryListData
        };
      case GET_FACTORY_LIST_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };