import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { verifyAuth } from './actions/';
import rootReducer from './reducers';
import logger from "redux-logger";
export default function configureStore(persistedState) {
	let store
	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		// dev code
		store = createStore(
			rootReducer,
			persistedState,
			applyMiddleware(thunkMiddleware, logger)
		);
	} else {
		// production code
		store = createStore(
			rootReducer,
			persistedState,
			applyMiddleware(thunkMiddleware)
		);
	}
	store.dispatch(verifyAuth());
	return store;
}
