export const RESPONSE_STATUS = {
  SUCCESS: 200,
  NEED_ACTION: 300,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  ERROR: 500,
};

export const containerType = [
  { id: "CNTP-0001", name: "20GP - General Purpose" },
  { id: "CNTP-0002", name: "40GP - General Purpose" },
  { id: "CNTP-0004", name: "45HC - High Cube" },
  { id: "CNTP-0005", name: "40HC - High Cube" },
  { id: "CNTP-0006", name: "20RF - Reefer" },
  { id: "CNTP-0007", name: "40RH - Reefer" }
]