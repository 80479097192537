import { getPortListData } from '../../services/Export/portList'

export const SEARCH_PORT_LIST_REQUEST = "SEARCH_PORT_LIST_REQUEST"
export const SEARCH_PORT_LIST_SUCCESS  = "SEARCH_PORT_LIST_SUCCESS"
export const SEARCH_PORT_LIST_FAILURE  = "SEARCH_PORT_LIST_FAILURE"

const getPortListRequest = () => {
    return {
      type: SEARCH_PORT_LIST_REQUEST
    }
  }
  
  const getPortListSuccess = porListData => {
    return {
      type: SEARCH_PORT_LIST_SUCCESS,
      porListData
    }
  }
  
  const getPortListFailure = () => {
    return {
      type: SEARCH_PORT_LIST_FAILURE
    }
  }

export const getPortData = (term) => async dispatch => {
    dispatch(getPortListRequest())
    const response = await getPortListData(term)
    if (response) {
        dispatch(getPortListSuccess(response))
        return true
    } else {
      dispatch(getPortListFailure())
      return false
    }
  }