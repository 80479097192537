import axios from 'axios'
import { base } from '../init'
import { getCookie } from 'utils/cookies';

const token = getCookie('token');

export const getTruckOrderSummaryData = async (orderID) => {
    try {
        const response = await axios.get(`${base}/vendorOrder/getVendorOrderDetailSummary?orderManagementID=${orderID}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        })
        return response.data.value
    } catch (err) {
        return []
    }
};