import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ProtectedRoute({ component: Component, ...rest }) {
	const { isAuthenticated, isVerifying } = useSelector(state => state.auth);
	return (
		<Route
			{...rest}
			render={props =>
				isVerifying ? (
					<div />
				) : isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/signIn',
							// pathname: "/",
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
}
