import { createNewFactory } from '../../services/Export/createFactory'

export const GET_CREATE_FACTORY_REQUEST = "GET_CREATE_FACTORY_REQUEST"
export const GET_CREATE_FACTORY_SUCCESS = "GET_CREATE_FACTORY_SUCCESS"
export const GET_CREATE_FACTORY_FAILURE = "GET_CREATE_FACTORY_FAILURE"

const getCreateFactoryRequest = () => {
    return {
      type: GET_CREATE_FACTORY_REQUEST
    }
  }
  
  const getCreateFactorySuccess = factoryCreated => {
    return {
      type: GET_CREATE_FACTORY_SUCCESS,
      factoryCreated
    }
  }
  
  const getCreateFactoryFailure = () => {
    return {
      type: GET_CREATE_FACTORY_FAILURE
    }
  }

export const createFactory = (data) => async dispatch => {
    dispatch(getCreateFactoryRequest())
    const response = await createNewFactory(data)
    if (response) {
        dispatch(getCreateFactorySuccess(response))
        return true
    } else {
      dispatch(getCreateFactoryFailure())
      return false
    }
  }