import {
    SUCCESS_SEND_EMAIL,
    REQUEST_SEND_EMAIL,
    ERROR_SEND_EMAIL
} from "actions/types"

export default (
    state = {
        requestSendEmail: false,
        successSendEmail: false,
        errorSendEmail: false
    },
    action
) => {
    switch (action.type) {
        case SUCCESS_SEND_EMAIL:
            return {
                ...state,
                successSendEmail: action.successSendEmail
            }
        case REQUEST_SEND_EMAIL:
            return {
                ...state,
                requestSendEmail: action.requestSendEmail
            }
        case ERROR_SEND_EMAIL:
            return {
                ...state,
                errorSendEmail: action.errorSendEmail
            }
        default:
            return state
    }
}