import {
    GET_CONTAINER_TYPE_LIST_REQUEST,
    GET_CONTAINER_TYPE_LIST_SUCCESS,
    GET_CONTAINER_TYPE_LIST_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        containerTypeListData : [],
    },
    action
  ) => {
    switch (action.type) {
      case GET_CONTAINER_TYPE_LIST_REQUEST:
        return {
          ...state,
          getError:false
        };
      case GET_CONTAINER_TYPE_LIST_SUCCESS:
        return {
          ...state,
          getError:false,
          containerTypeListData: action.containerTypeListData
        };
      case GET_CONTAINER_TYPE_LIST_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };