import {
    CREATE_NEW_CART_REQUEST,
    CREATE_NEW_CART_SUCCESS,
    CREATE_NEW_CART_FAILURE
  } from "actions";
  
  export default (
    state = {
        getError: false,
        newCart : {},
    },
    action
  ) => {
    switch (action.type) {
      case CREATE_NEW_CART_REQUEST:
        return {
          ...state,
          getError:false
        };
      case CREATE_NEW_CART_SUCCESS:
        return {
          ...state,
          getError:false,
          newCart: action.newCart
        };
      case CREATE_NEW_CART_FAILURE:
          return {
          ...state,
          getError:true,
        };     
      default:
        return state;
    }
  };