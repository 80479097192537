import {
	GET_VENDOR_LIST_DRIVER,
	GET_VENDOR_LIST_DRIVER_SUCCESS,
	GET_VENDOR_LIST_DRIVER_FAILED,
	INSERT_VENDOR_DRIVER,
	INSERT_VENDOR_DRIVER_SUCCESS,
	INSERT_VENDOR_DRIVER_FAILED,
	UPDATE_VENDOR_DRIVER,
	UPDATE_VENDOR_DRIVER_SUCCESS,
	UPDATE_VENDOR_DRIVER_FAILED,
	DELETE_VENDOR_DRIVER,
	DELETE_VENDOR_DRIVER_SUCCESS,
	DELETE_VENDOR_DRIVER_FAILED,
} from 'actions/types';
import { getCookie } from 'utils/cookies';

import { getListDriver, insertDriver, updateDriver, deleteDriver } from 'services/vendor';

export const getVendorListDriver = () => async dispatch => {
	try {
		dispatch({ type: GET_VENDOR_LIST_DRIVER });
		const res = await getListDriver();
		if (res.status === 200) {
			dispatch({ type: GET_VENDOR_LIST_DRIVER_SUCCESS, payload: res.data.value });
		} else {
			dispatch({ type: GET_VENDOR_LIST_DRIVER_FAILED, payload: res.data });
		}
	} catch (err) {
		console.log(err);
		dispatch({ type: GET_VENDOR_LIST_DRIVER_FAILED, payload: err.message });
	}
};

export const insertVendorDriver = payload => async dispatch => {
	try {
		dispatch({ type: INSERT_VENDOR_DRIVER });
		const res = await insertDriver(payload);
		console.log(res.data);
		if (res.data.value.statusSuccess === 1) {
			dispatch({
				type: INSERT_VENDOR_DRIVER_SUCCESS,
				payload: res.data,
				message: res.data.value.messages,
			});
		} else {
			dispatch({
				type: INSERT_VENDOR_DRIVER_FAILED,
				payload: res.data.value.messages,
				message: res.data.value.messages,
			});
		}
		return res.data.value.statusSuccess === 1;
	} catch (err) {
		console.log(err.message);
		dispatch({ type: INSERT_VENDOR_DRIVER_FAILED, payload: err.message });
	}
};

export const updateVendorDriver = payload => async dispatch => {
	const driverId = payload.driverId;
	try {
		dispatch({ type: UPDATE_VENDOR_DRIVER });
		const res = await updateDriver(payload);
		if (res.data.value.statusSuccess === 1) {
			dispatch({
				type: UPDATE_VENDOR_DRIVER_SUCCESS,
				driver: res.data,
				driverId: driverId,
				message: res.data.value.messages,
			});
		} else {
			dispatch({
				type: UPDATE_VENDOR_DRIVER_FAILED,
				payload: res.data.value.messages,
				message: res.data.value.messages,
			});
		}
		return res.data.value.statusSuccess === 1;
	} catch (err) {
		console.log(err.message);
		dispatch({ type: UPDATE_VENDOR_DRIVER_FAILED, payload: err.message });
	}
};

export const deleteVendorDriver = driverId => async dispatch => {
	const vendorId = getCookie('userId');
	const payload = { driverId: driverId, vendorId: vendorId };
	console.log(payload);
	try {
		dispatch({ type: DELETE_VENDOR_DRIVER });
		const res = await deleteDriver(payload);
		if (res.data.value.statusSuccess === 1) {
			dispatch({
				type: DELETE_VENDOR_DRIVER_SUCCESS,
				payload: res.data,
				driverId: driverId,
				message: res.data.value.message,
			});
		} else {
			dispatch({
				type: DELETE_VENDOR_DRIVER_FAILED,
				payload: res.data.value.message,
				message: res.data.value.messages,
			});
		}
		return res.data.value.statusSuccess === 1;
	} catch (err) {
		console.log(err.message);
		dispatch({ type: DELETE_VENDOR_DRIVER_FAILED, payload: err.message });
	}
};
