import {
  UPLOAD_GATEPASS_DOCUMENT,
  UPLOAD_GATEPASS_DOCUMENT_FAILED,
  UPLOAD_GATEPASS_DOCUMENT_SUCCESS,

  VERIFY_GATEPASS,
  VERIFY_GATEPASS_SUCCESS,
  VERIFY_GATEPASS_FAILED,

  REQUEST_GATEPASS,
  REQUEST_GATEPASS_SUCCESS,
  REQUEST_GATEPASS_FAILED,
  VERIFY_GATEPASS_CLEAR,

  GET_ONBEHALF_NPWP,
  GET_ONBEHALF_NPWP_SUCCESS,
  GET_ONBEHALF_NPWP_FAILED,

  CREATE_ONBEHALF_NPWP,
  CREATE_ONBEHALF_NPWP_FAILED,
  CREATE_ONBEHALF_NPWP_SUCCESS,

  GET_CUSTOM_CODE_DOC_SUCCESS,
  GET_CUSTOM_CODE_DOC_FAILED,
  GET_CUSTOM_CODE_DOC,

} from 'actions/types';
import { uploadDocumentServices } from 'services/uploadFile/documentGatepass';
import {
  verifyGatepassServices,
  requestGatepassServices,
  createOnBehalfNpwpServices,
  getOnBehalfNpwpServices,
  getCustomCodeDocServices,
} from 'services/gatepass';

export const uploadDocumentGatepass = (payload) => async dispatch => {
  try {
    dispatch({ type: UPLOAD_GATEPASS_DOCUMENT });
    const response = await uploadDocumentServices(payload);
    if (response.status === 200) {
      dispatch({
        type: UPLOAD_GATEPASS_DOCUMENT_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({ type: UPLOAD_GATEPASS_DOCUMENT_FAILED });
    }
  } catch (error) {
    dispatch({ type: UPLOAD_GATEPASS_DOCUMENT_FAILED });
  }
};

export const verifyGatepass = (payload) => async dispatch => {
  try {
    dispatch({ type: VERIFY_GATEPASS });
    const response = await verifyGatepassServices(payload);
    if (response.status === 200) {
      dispatch({
        type: VERIFY_GATEPASS_SUCCESS,
        payload: response.data,
      });
    } else {
      console.log('response', response);
      dispatch({
        type: VERIFY_GATEPASS_FAILED,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: VERIFY_GATEPASS_FAILED,
      payload: error.message,
    });
  }
};

export const resetVerifyGatepass = () => async dispatch => dispatch({ type: VERIFY_GATEPASS_CLEAR });

export const requestGatepass = (payload) => async dispatch => {
  try {
    dispatch({ type: REQUEST_GATEPASS });
    const response = await requestGatepassServices(payload);
    if (response.status === 200) {
      dispatch({
        type: REQUEST_GATEPASS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({ type: REQUEST_GATEPASS_FAILED });
    }
  } catch (error) {
    dispatch({ type: REQUEST_GATEPASS_FAILED });
  }
};

export const getOnBehalfNpwp = (payload) => async dispatch => {
  try {
    dispatch({ type: GET_ONBEHALF_NPWP });
    const response = await getOnBehalfNpwpServices(payload);
    if (response.status === 200) {
      dispatch({
        type: GET_ONBEHALF_NPWP_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({ type: GET_ONBEHALF_NPWP_FAILED });
    }
  } catch (error) {
    dispatch({ type: GET_ONBEHALF_NPWP_FAILED });
  }
};

export const createOnBehalfNpwp = (payload) => async dispatch => {
  try {
    dispatch({ type: CREATE_ONBEHALF_NPWP });
    const response = await createOnBehalfNpwpServices(payload);
    if (response.status === 200) {
      dispatch({
        type: CREATE_ONBEHALF_NPWP_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({ type: CREATE_ONBEHALF_NPWP_FAILED });
    }
  } catch (error) {
    dispatch({ type: CREATE_ONBEHALF_NPWP_FAILED });
  }
};

export const getCustomCodeDoc = (payload) => async dispatch => {
  try {
    dispatch({ type: GET_CUSTOM_CODE_DOC });
    const response = await getCustomCodeDocServices(payload);
    if (response.status === 200) {
      dispatch({
        type: GET_CUSTOM_CODE_DOC_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({ type: GET_CUSTOM_CODE_DOC_FAILED });
    }
  } catch (error) {
    dispatch({ type: GET_CUSTOM_CODE_DOC_FAILED });
  }
};
