import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'

import i18n from './i18n'
import App from './App'
import configureStore from './configureStore'
import LoadingLoad from './components/loading/loadingLoad'

const store = configureStore()

function Root() {
  return (
    <Suspense fallback={<LoadingLoad />}>
      <Provider store={store}>
        <Router>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </Router>
      </Provider>
    </Suspense>
  )
}

export default Root