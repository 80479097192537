import { createNewCustomerOrder } from '../../services/Export/placeNewCustomerOrder'

export const CREATE_NEW_ORDER_REQUEST = "CREATE_NEW_ORDER_REQUEST"
export const CREATE_NEW_ORDER_SUCCESS = "CREATE_NEW_ORDER_SUCCESS"
export const CREATE_NEW_ORDER_FAILURE = "CREATE_NEW_ORDER_FAILURE"

const createNewOrderRequest = () => {
    return {
      type: CREATE_NEW_ORDER_REQUEST
    }
  }
  
  const createNewOrderSuccess = newCustomerOrder => {
    return {
      type: CREATE_NEW_ORDER_SUCCESS,
      newCustomerOrder
    }
  }
  
  const createNewOrderFailure = () => {
    return {
      type: CREATE_NEW_ORDER_FAILURE
    }
  }

export const createNewOrder = (order) => async dispatch => {
    dispatch(createNewOrderRequest())
    const response = await createNewCustomerOrder(order)
    if (response) {
        dispatch(createNewOrderSuccess(response))
        return true
    } else {
      dispatch(createNewOrderFailure())
      return false
    }
  }