import { BookingApi } from 'services';
import { RESPONSE_STATUS } from 'utils/contants';

export const searchSchedules = data => {
	return dispatch => {
		dispatch({
			type: 'SEARCH_SCHEDULES',
			payload: data,
		});
	};
};

// export const getSchedules = (data) => {
//   return async (dispatch) => {
//     const response = await BookingApi.getSchedules(data);
//     if (response.status === RESPONSE_STATUS.SUCCESS) {
//       dispatch({
//         type: "SCHEDULES_GET_DONE",
//         payload: response.data.payload,
//       });
//     }
//   };
// };
