import {
	GET_VENDOR_LIST_ORDER,
	GET_VENDOR_LIST_ORDER_SUCCESS,
	GET_VENDOR_LIST_ORDER_FAILED,
	GET_VENDOR_LIST_CN,
	GET_VENDOR_LIST_CN_SUCCESS,
	GET_VENDOR_LIST_CN_FAILED,
	GET_VENDOR_LIST_ORDER_DETAIL,
	GET_VENDOR_LIST_ORDER_DETAIL_SUCCESS,
	GET_VENDOR_LIST_ORDER_DETAIL_FAILED,
	GET_VENDOR_UNPAID_CN,
	GET_VENDOR_UNPAID_CN_SUCCESS,
	GET_VENDOR_UNPAID_CN_FAILED,
	GET_VENDOR_PAID_CN,
	GET_VENDOR_PAID_CN_SUCCESS,
	GET_VENDOR_PAID_CN_FAILED,
	GET_VENDOR_LIST_CONTAINER,
	GET_VENDOR_LIST_CONTAINER_SUCCESS,
	GET_VENDOR_LIST_CONTAINER_FAILED,
	VENDOR_ASSIGN_DRIVER,
	VENDOR_ASSIGN_DRIVER_SUCCESS,
	VENDOR_ASSIGN_DRIVER_FAILED,
} from 'actions/types';

const initialState = {
	isLoading: false,
	listOrderData: [],
	listCN: [],
	listOrderDetail: [],
	listDriver: [],
	listVehicle: [],
	listContainer: [],
	unpaidCN: 0,
	paidCN: 0,
	totalCN: 0,
	error: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_VENDOR_LIST_ORDER:
			return {
				...state,
				isLoading: true,
			};
		case GET_VENDOR_LIST_ORDER_SUCCESS:
			return {
				...state,
				isLoading: false,
				listOrderData: action.payload,
			};
		case GET_VENDOR_LIST_ORDER_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case GET_VENDOR_LIST_CN:
			return {
				...state,
				isLoading: true,
			};
		case GET_VENDOR_LIST_CN_SUCCESS:
			return {
				...state,
				isLoading: false,
				listCN: action.payload,
			};
		case GET_VENDOR_LIST_CN_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case GET_VENDOR_LIST_ORDER_DETAIL:
			return {
				...state,
				isLoading: true,
			};
		case GET_VENDOR_LIST_ORDER_DETAIL_SUCCESS:
			return {
				...state,
				isLoading: false,
				listOrderDetail: action.payload,
			};
		case GET_VENDOR_LIST_ORDER_DETAIL_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case GET_VENDOR_UNPAID_CN:
			return {
				...state,
				isLoading: true,
			};
		case GET_VENDOR_UNPAID_CN_SUCCESS:
			return {
				...state,
				isLoading: false,
				unpaidCN: action.payload,
			};
		case GET_VENDOR_UNPAID_CN_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case GET_VENDOR_PAID_CN:
			return {
				...state,
				isLoading: true,
			};
		case GET_VENDOR_PAID_CN_SUCCESS:
			return {
				...state,
				isLoading: false,
				paidCN: action.payload,
			};
		case GET_VENDOR_PAID_CN_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case GET_VENDOR_LIST_CONTAINER:
			return {
				...state,
				// isLoading: true,
			};
		case GET_VENDOR_LIST_CONTAINER_SUCCESS:
			return {
				...state,
				isLoading: false,
				listContainer: action.payload,
			};
		case GET_VENDOR_LIST_CONTAINER_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case VENDOR_ASSIGN_DRIVER:
			return {
				...state,
				isLoading: true,
			};
		case VENDOR_ASSIGN_DRIVER_SUCCESS:
			return {
				...state,
				isLoading: false,
				message: action.message,
			};
		case VENDOR_ASSIGN_DRIVER_FAILED:
			return {
				...state,
				isLoading: false,
				message: action.message,
				error: action.payload,
			};
		default:
			return state;
	}
};
