import { getPortData } from '../../services/Export/searchPort'

export const SEARCH_PORT_REQUEST = "SEARCH_PORT_REQUEST"
export const SEARCH_PORT_SUCCESS  = "SEARCH_PORT_SUCCESS"
export const SEARCH_PORT_FAILURE  = "SEARCH_PORT_FAILURE"

const getPortequest = () => {
    return {
      type: SEARCH_PORT_REQUEST
    }
  }
  
  const getPortSuccess = searchPortData => {
    return {
      type: SEARCH_PORT_SUCCESS,
      searchPortData
    }
  }
  
  const getPortFailure = () => {
    return {
      type: SEARCH_PORT_FAILURE
    }
  }

export const getPort = (term) => async dispatch => {
    dispatch(getPortequest())
    const response = await getPortData(term)
    if (response) {
        dispatch(getPortSuccess(response))
        return true
    } else {
      dispatch(getPortFailure())
      return false
    }
  }