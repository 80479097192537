import axios from 'axios'
import { base } from '../init'
import { getCookie } from 'utils/cookies';

const token = getCookie('token');

export const deleteCartItems = async (cartData) => {
    try {
        const response = await axios.post(`${base}/cart/public/deleteCartItem`, cartData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (err) {
        return []
    }
};