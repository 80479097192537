import { getTruckOrderSummaryData } from '../../services/TruckBookingList/getOrderInfoSummary'

export const GET_ORDER_SUMMARY_REQUEST = "GET_ORDER_SUMMARY_REQUEST"
export const GET_ORDER_SUMMARY_SUCCESS  = "GET_ORDER_SUMMARY_SUCCESS"
export const GET_ORDER_SUMMARY_FAILURE  = "GET_ORDER_SUMMARY_FAILURE"

const getOrderSummaryRequest = () => {
    return {
      type: GET_ORDER_SUMMARY_REQUEST
    }
  }
  
  const getOrderSummarySuccess = orderSummaryData => {
    return {
      type: GET_ORDER_SUMMARY_SUCCESS,
      orderSummaryData
    }
  }
  
  const getOrderSummaryFailure = () => {
    return {
      type: GET_ORDER_SUMMARY_FAILURE
    }
  }

export const getOrderInfoSummaryData = (orderID) => async dispatch => {
    dispatch(getOrderSummaryRequest())
    const response = await getTruckOrderSummaryData(orderID)
    if (response) {
        dispatch(getOrderSummarySuccess(response))
        return true
    } else {
      dispatch(getOrderSummaryFailure())
      return false
    }
  }